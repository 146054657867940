import React, { useState } from "react";
import {
  Box,
  makeStyles,
  TableCell,
  TableContainer,
  IconButton,
  TableHead,
  TableRow,
  Table,
  Typography,
  withStyles,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { sortAddress } from "../../utils";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BiCopy } from "react-icons/bi";
import { toast } from "react-toastify";
import { FiCheck } from "react-icons/fi";
import { CiWarning } from "react-icons/ci";

const useStyles = makeStyles((theme) => ({}));
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      //   backgroundColor: "#ffdead87",
    },
  },
}))(TableRow);
export default function TableChild({
  value,
  page,
  isChecked,
  setIsChecked,
  i,
  setUsereSlect,
  usereSlect,
}) {
  const classes = useStyles();
  // const [isChecked, setIsChecked] = useState(false);
  return (
    <StyledTableRow>
      <TableCell> {(page - 1) * 15 + i + 1}</TableCell>
      <TableCell>{value?.email ? value?.email : "N/A"}</TableCell>
      <TableCell>
        {value?.walletAddress ? sortAddress(value?.walletAddress) : "N/A"}
        {value?.walletAddress ? (
          <CopyToClipboard text={value?.walletAddress}>
            <IconButton>
              <BiCopy
                style={{ color: "#fff" }}
                onClick={() => toast.info("Copied")}
              />
            </IconButton>
          </CopyToClipboard>
        ) : (
          ""
        )}
      </TableCell>

      <TableCell>
        {/* {value.withdraw_status === "SUCCESS" ? (
          "Withdraw Successful"
        ) : ( */}
        {/* {console.log("slkhfksjdf", value?._id)} */}
        {/* <Checkbox
          color="primary"
          className="hoverColor"
          onClick={(e) => {
            setIsChecked(!isChecked);
            if (!isChecked) {
              setUsereSlect([...usereSlect, value]);
            } else {
              const filterFun = usereSlect.filter((data) => {
                console.log("slksdhfksjdf", data);

                return data?._id !== value._id;
              });
              setUsereSlect(filterFun);
            }
          }}
          checked={isChecked}
        /> */}
        {isChecked ? (
          <FiCheck style={{ fontSize: "20px" }} />
        ) : (
          <CiWarning style={{ fontSize: "20px" }} />
        )}

        {/* )} */}
      </TableCell>
    </StyledTableRow>
  );
}
