import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  InputBase,
  Drawer,
  Grid,
  Link,
  MenuItem,
  Box,
  Container,
  List,
  Typography,
  FormGroup,
  fade,
  FormControlLabel,
  Menu,
  Snackbar,
} from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";

import MenuIcon from "@material-ui/icons/Menu";

import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { sortAddress, swichNetworkHandler } from "../../utils";

import { UserContext } from "../../../src/context/User";
import { Alert } from "@material-ui/lab";
import {
  ACTIVE_NETWORK,
  NetworkContextName,
} from "../../../src/constants/index";

import ConnectWallet from "../ConnectWalletPopUp/";

import Logo from "./../../components/Header/Logo";

const headersData = [
  //   {
  //     label: "Home",
  //     href: "https://satellitecrypto.tech/#/app/landingpage",
  //   },
  {
    label: "HOME",
    href: "/",
  },
  {
    label: "BUY",
    href: "/app/Buycoin",
  },
  {
    label: "STAKE",
    href: "/app/Network",
  },
  //   {
  //     label: "Support",
  //     href: "/FAQ",
  //   },

  // {
  //   label: "Create",
  //   href: "/create",
  // },
];

const useStyles = makeStyles((theme) => ({
  header: {
    height: "68px",
    position: "fixed",
    backgroundColor: "#000000",
    paddingBottom: "6rem",
    // backgroundColor: "rgb(169 40 235)",

    paddingRight: "0",
    paddingLeft: "0px",
    "@media (max-width: 1280px)": {
      paddingLeft: "0",
      paddingRight: "0px",
      height: "70px",
      paddingTop: "5px",
    },
    "@media (max-width: 900px)": {
      paddingLeft: "0px",
      paddingRight: "0px",
      height: "70px",
      paddingTop: "5px",
    },
  },
  logoHeader: {
    paddingTop: "7px",
    paddingBottom: "7px",
    "@media(max-width:1024px)": {
      paddingTop: "0rem",
      paddingBottom: "0",
    },
  },

  menuButton: {
    fontSize: "13px",
    height: "48px",
    fontWeight: "400",
    borderRadius: 0,
    marginLeft: "16px",
    minWidth: "auto",
    color: "#fff",
    // padding: "10px 24px",
    textTransform: "uppercase",
    letterSpacing: "1px",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "1.75",
    },
    "&:active": {
      color: "#fff",
    },
    "&:last-child": {
      backgroundColor: "#1ed760",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    marginTop: "1rem",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "200px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#000",
    width: "260px",
  },
  // drop: {
  //   color: "#000",
  // },

  drawericon: {
    color: "#000",
    position: "absolute",
    top: "20px",
    right: "0px",
    fontSize: "25px",
    "@media(max-width:767px)": {
      top: "9px",
    },
  },
  logoImg: {
    width: "100%",

    margin: " 1px 15px 1px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      // margin: " 1px 15px 1px 0px",
      // width: "52px",
      // height: "44.5px",
    },
  },
  menuMobile: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    borderBottom: "1px solid #3e3e3e",
    padding: "16px",
    // marginTop: "-15px",
    marginTop: "0px",
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
    padding: "13px",
  },
  search: {
    height: "36px",
    marginTop: "7px",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 20,
    width: "160px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "257px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    // color: "inherit",
    fontSize: "16px",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  box2: {
    marginTop: "20px",
    marginBottom: "40px",
  },
  box3: {
    marginBottom: "30px",
  },
  createbutton: {
    fontSize: "14px ",
    // marginTop: "15px",
    fontWeight: 700,
    height: "44px ",
    background: "linear-gradient(280deg,#78FDFF 0%, #1D84C7 100%)",
    color: "#fff",

    borderRadius: "10px",

    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: "45px ",
      minWidth: "120px ",
    },
    "&:hover": {
      color: "#fff",
      background: "#1D84C7",
      transition: "none",
    },
  },
}));

export default function Header() {
  const classes = useStyles();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { activate, account, deactivate, chainId } = useWeb3React();
  const auth = useContext(UserContext);
  console.log("authsldf", auth?.profile?.email);
  const [errmsg, seterrmsg] = React.useState("");
  const [openWalletConnect, setOpenWalletConnect] = React.useState(false);

  const [errpopup, seterrpopup] = React.useState("");
  const [checkedTerms, setCheckedTerms] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [errhandleClose, seterrhandleClose] = React.useState(false);
  const assessToken = window.sessionStorage.getItem("token");
  const [emailpopup, setemailpopup] = useState(true);
  const user = useContext(UserContext);
  const userType = user?.userDataStatus?.userType;
  console.log("ksjdhfkjsdhf", user?.userDataStatus?.userType);
  const handleClosebutton = () => {
    setemailpopup(false);
  };
  useEffect(() => {
    if (window.location.pathname === "/profile") {
      setemailpopup(false);
    }
  }, [window.location.pathname === "/profile"]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleChangecheckedTerms = (event) => {
    setCheckedTerms(event.target.checked);
  };

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen = () => {
    // setOpen(    // window.sessionStorage.removeItem("token");
    // window.sessionStorage.removeItem("userAddress");true);
    // location.reload();
  };
  // useEffect(() => {
  //   if (
  //     window.sessionStorage.getItem("token") &&
  //     window.sessionStorage.getItem("userAddress")
  //   ) {
  //     history.push("/");
  //   }
  // }, [
  //   window.sessionStorage.getItem("token"),
  //   window.sessionStorage.getItem("userAddress"),
  // ]);
  const [DataDisconnect, setDataDisconnect] = useState("initialState");

  const disconnectHandler = () => {
    // setOpen(true);
    window.sessionStorage.removeItem("token");
    window.sessionStorage.removeItem("userAddress");
    // href = "/";
    // window.sessionStorage.removeItem("userAddress");
    // location.pathname("/");
    seterrhandleClose(true);
    seterrpopup(true);
    seterrmsg("Disconnect Successful");
    setDataDisconnect("");
    window.location.href = "/";
    // history.push("/");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    logoHeader,
    data,
    onWalletConnectHandler,
    header,
    menuMobile,
    menuButton,
    toolbar,
    drawerContainer,
    drawericon,
    logoImg,
    logoDrawer,
    containerHeight,
    search,
    searchIcon,
    mainHeader,
    inputInput,
    inputRoot,
  } = useStyles();
  const history = useHistory();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 980
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" className="gutterclx">
        <Toolbar className={toolbar}>
          {openWalletConnect && (
            <ConnectWallet
              open={openWalletConnect}
              handleClose={() => setOpenWalletConnect(false)}
            />
          )}
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            style={{
              paddingLeft: "0px",
            }}
          >
            {/* <SearchBox
            search={search}
            inputRoot={inputRoot}
            inputInput={inputInput}
            searchIcon={searchIcon}
          /> */}
            {getMenuButtons()}
            {userType === "Admin" ? "" : <>&nbsp;&nbsp;&nbsp;&nbsp;</>}

            {userType === "Admin" ? (
              <Button
                onClick={() =>
                  history.push({
                    pathname: "/app/WhitelistUserList",
                  })
                }
              >
                <Typography
                  style={{
                    fontSize: "13px",
                    color: "white",
                    paddingRight: "12px",
                    paddingLeft: "9px",
                  }}
                >
                  Whitelist User
                </Typography>
              </Button>
            ) : (
              ""
            )}
            {account ? (
              <Button
                // className={`${classes.buttonright12} buttonright `}
                variant="contained"
                size="large"
                // component={Link}
                // to="/app/home"
                style={{
                  whiteSpace: "nowrap",
                }}
                disabled
                // onClick={() => setOpenWalletConnect(true)}
              >
                {sortAddress(account)}
              </Button>
            ) : (
              <Button
                // className={`${classes.buttonright12} buttonright `}
                className={classes.buttonright}
                variant="contained"
                size="large"
                // component={Link}
                // to="/app/home"
                style={{
                  whiteSpace: "nowrap",
                }}
                className={classes.createbutton}
                onClick={() => setOpenWalletConnect(true)}
              >
                Connect Wallet
              </Button>
            )}
            {/* <Button variant="contained" size="small" color="secondary" onClick={handleClickOpen}>
        Connect
      </Button> */}
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo.png" alt="logo" />

            {getDrawerChoices()}

            {/* {!auth?.userLoggedIn ? (
              <Link className="MobileButton" onClick={handleClickOpen}>
                Connect
              </Link>
            ) : (
              <Link
                onClick={disconnectHandler}
                href="/"
                className="MobileButton"
              >
                Disconnect
              </Link>
            )} */}
            {/* <Box style={{ padding: "15px" }}>
              {auth?.userLoggedIn ? (
                <Button
                  variant="contained"
                  color="primary"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  Profile
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={() => setOpen(true)}
                >
                  Connect
                </Button>
              )}
            </Box> */}
            {userType === "Admin" ? (
              <Box
                style={{
                  width: "100%",
                  borderBottom: "1px solid #3e3e3e",
                  padding: " 14px 0px",
                }}
              >
                <Button
                  onClick={() =>
                    history.push({
                      pathname: "/app/WhitelistUserList",
                    })
                  }
                >
                  <Typography
                    style={{
                      fontSize: "15px",
                      color: "white",
                      paddingRight: "12px",
                      paddingLeft: "9px",
                    }}
                  >
                    Whitelist User
                  </Typography>
                </Button>
              </Box>
            ) : (
              ""
            )}
            <Box style={{ padding: "14px" }}>
              {account ? (
                <Button
                  className={`${classes.buttonright12} buttonright `}
                  variant="contained"
                  size="large"
                  // component={Link}
                  // to="/app/home"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                  disabled
                  // onClick={() => setOpenWalletConnect(true)}
                >
                  {sortAddress(account)}
                </Button>
              ) : (
                <Button
                  // className={`${classes.buttonright12} buttonright `}
                  className={classes.buttonright}
                  variant="contained"
                  size="large"
                  // component={Link}
                  // to="/app/home"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                  className={classes.createbutton}
                  onClick={() => setOpenWalletConnect(true)}
                >
                  Connect Wallet
                </Button>
              )}
            </Box>
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={10}>
            {/* <SearchBox
              search={search}
              inputRoot={inputRoot}
              inputInput={inputInput}
              searchIcon={searchIcon}
            /> */}
          </Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon width="60px" height="60px" style={{ color: "#fff" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Link
            {...{
              component: RouterLink,
              to: href,
              color: "inherit",
              style: { textDecoration: "none" },
              key: label,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Link>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box className={logoHeader}>
      <a
        style={{ color: "white" }}
        href="https://landing-metaverse.mobiloitte.org/"
        target="_blank"
      >
        <Logo className={logoImg} />
      </a>
    </Box>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: RouterLink,
              className: menuButton,
            }}
          >
            {label}
          </Button>
        </>
      );
    });
  };

  return (
    <>
      <AppBar
        className={header}
        elevation={0}
        style={{ backgroundColor: "rgb(0, 0, 0)" }}
      >
        <Box
          maxWidth={history.location.pathname !== "/" ? "lg" : "lg"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Box>
      </AppBar>

      {/* <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose1}
      >
        <MenuItem style={{ backgroundColor: "purple" }}>
          <RouterLink
            style={{ color: "white" }}
            // onClick={history.push("/")}
            to="/wallet"
            // link={component}
          >
            Wallet
          </RouterLink>
        </MenuItem>
        <MenuItem style={{ backgroundColor: "purple" }}>
          <RouterLink
            style={{ color: "white" }}
            // onClick={history.push("/")}
            to="/profile"
            // link={component}
          >
            My Profile
          </RouterLink>
        </MenuItem>
        <MenuItem style={{ backgroundColor: "purple" }}>
          <RouterLink style={{ color: "white" }} to="/nft">
            My Nft
          </RouterLink>
        </MenuItem>
        <MenuItem
          onClick={disconnectHandler}
          style={{ backgroundColor: "purple", color: "#fff" }}
        >
          Disconnect
        </MenuItem>
      </Menu> */}

      {openWalletConnect && (
        <ConnectWallet
          open={openWalletConnect}
          handleClose={() => setOpenWalletConnect(false)}
        />
      )}
      <Dialog
        open={account && chainId !== ACTIVE_NETWORK}
        minWidth="xs"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ width: "atuo !important" }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Netwok Change Error"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box pb={3}>
              <Typography>Please switch to {NetworkContextName}</Typography>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "10px 0px",
                }}
              >
                {/* <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => swichNetworkHandler()}
                  >
                    Switch Netwoek
                  </Button> */}
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={errpopup}
        autoHideDuration={5000}
        onClose={errhandleClose}
      >
        <Alert onClose={errhandleClose} severity="info">
          {errmsg}
        </Alert>
      </Snackbar>
    </>
  );
}
