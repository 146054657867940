import {
  Button,
  Dialog,
  DialogContent,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import classNames from "classnames";
import React, { useContext, useEffect } from "react";
// import { SUPPORTED_WALLETS } from "../../connectors";
import { SUPPORTED_WALLETS } from "../../connectors";
import { UserContext } from "../../context/User";
import useStyles from "../Header/styles";

export default function ConnectWallet({ open, handleClose }) {
  const user = useContext(UserContext);
  const { account, activate } = useWeb3React();

  useEffect(() => {
    if (account) {
      handleClose();
    }
  }, [account]);

  const connectWallet = (connector) => {
    // setSelectedWallet(item.name);
    // setIsLoading(true);
    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (connector && connector.walletConnectProvider?.wc?.uri) {
      // setIsLoading(false);
      connector.walletConnectProvider = undefined;
    }
    activate(connector, undefined, true).catch((error) => {
      if (error) {
        // setIsLoading(false);
        // setErrorEthereum(true);
        console.log("ERROR", error);
        activate(connector);
      }
    });
  };
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableScrollLock={true}
      className={classes.connectWalletModal}
    >
      <Box textAlign="center" style={{ margin: "16px" }}>
        <Typography variant="h3" style={{ color: "#fff" }}>
          Connect wallet
        </Typography>
      </Box>
      <DialogContent>
        <Box style={{ padding: "18px" }}>
          <Grid container spacing={2}>
            {SUPPORTED_WALLETS.map((item, i) => {
              return (
                <Grid xm={12} sm={12} key={i}>
                  <Box mt={2} display="flex" style={{ paddingTop: "10px" }}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        window.sessionStorage.removeItem("walletName");
                        window.sessionStorage.setItem("walletName", item.name);
                        user.connectWallet(item.data);
                      }}
                    >
                      <Box textAlign="center" display="flex">
                        <img
                          src={item.data.iconName}
                          alt={""}
                          width="25"
                          height="25"
                          style={{ marginRight: 10 }}
                        />
                      </Box>
                      {item.data.name}
                    </Button>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </DialogContent>
      <Box style={{ margin: "16px", textAlign: "center" }}>
        <Button onClick={handleClose} variant="contained" color="secondary">
          Close
        </Button>
      </Box>
    </Dialog>
  );
}
