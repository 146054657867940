import React from "react";
import {
  Box,
  Typography,
  Container,
  makeStyles,
  Grid,
  Button,
} from "@material-ui/core";
import { FcLock } from "react-icons/fc";

const useStyles = makeStyles((theme) => ({
  mainbox: {
    padding: "60px 0px 60px",

    [theme.breakpoints.down("sm")]: {
      padding: "60px 0px 30px",
    },
  },
  image: {
    maxWidth: "425px",
  },
  gridflex: {
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    "@media(max-width:959px)": {
      justifyContent: "left",
      display: "block",
    },
  },

  TypographyBox: {
    "& h3": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "40px",
      lineHeight: "36px",
      marginBottom: "20px",
      background:
        "linear-gradient(180deg, #78FDFF 0%, rgba(29, 132, 199, 0) 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",

      [theme.breakpoints.down("xs")]: {
        fontSize: "26px",
      },
    },

    "& h4": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "26px",
      color: "#fff",
      marginBottom: "10px",
    },
  },
}));

function Staking() {
  const classes = useStyles();
  return (
    <Box className={classes.mainbox}>
      <Container>
        <Grid container spacing={5}>
          <Grid item lg={7} md={6} sm={12} xs={12}>
            <Box mt={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Box className={classes.TypographyBox}>
                    <Box align="left">
                      <Typography variant="h3">How does it work?</Typography>
                    </Box>

                    <Box mt={1} align="left" style={{ maxWidth: "700px" }}>
                      {" "}
                      <Typography variant="h4">
                        As the Tarality Metaverse platform continues to grow and evolve, we're excited to announce some new features that will be available to users in the near future. One of the most anticipated features is the ability for users to stake their Tarality tokens and make satellites. This will allow users to earn Revolution tokens, which can be used for a variety of purposes on the platform, including purchasing NFTs.
                      </Typography>
                      <Typography variant="h4">
                        This new feature is just one of the many ways we're planning on making the Metaverse a more dynamic and exciting place to explore.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={5} md={6} sm={12} xs={12} className={classes.gridflex}>
            <Box className={classes.image}>
              <img src="images/robot1.png" alt="" width="100%" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Staking;
