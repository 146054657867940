import React, { createContext, useEffect, useState } from "react";
import { injected, SUPPORTED_WALLETS } from "../connectors";
import { useWeb3React } from "@web3-react/core";
import {
  ACTIVE_NETWORK,
  default_RPC_URL,
  NetworkDetails,
  NewStakingContracAddress,
  getNetworkDetails,
} from "../constants/";
import { getContract, getWeb3ContractObject } from "../utils";
import KingShibaStakingABI from "../abis/KingShibaStakingABI.json";
import axios from "axios";
import { toast } from "react-toastify";
import Web3 from "web3";
import ApiConfig from "../../src/config/ApiConfig";
export const UserContext = createContext();
const setSession = async (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem("token", token);
  } else {
    sessionStorage.removeItem("token");
  }
};

export default function AuthProvider(props) {
  const { activate, deactivate, account, library, chainId } = useWeb3React();
  const [isLogin, setIsLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState([]);
  const [errorMsg] = useState("");
  const [successMSG, setSuccessMSG] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [userData, setUserData] = useState();
  const [userDataStatus, setUserDataStatus] = useState();
  console.log("dslfjskdfj", userDataStatus);
  const [controlData, setControlData] = useState();
  const [yourWalletBalance, setYourWalletBalance] = useState(0);
  const [userstakedata, setuserstakedata] = useState("");
  const [adminAddress, setAdminAddress] = useState({
    contract1: "",
    contrsct2: "",
    contract3: "",
    contract4: "",
  });

  const httpProvider = new Web3.providers.HttpProvider(default_RPC_URL);
  const web3 = new Web3(httpProvider);

  useEffect(() => {
    if (account) {
      GetTotalStaked();
    }
  }, [account]);

  const GetTotalStaked = async () => {
    try {
      const contractObj = await getWeb3ContractObject(
        KingShibaStakingABI,
        NewStakingContracAddress,
        default_RPC_URL
      );

      const userInfo = await contractObj.methods.userInfo(account).call();
      const UserStakedData = web3.utils.fromWei(userInfo?.amount.toString());
      console.log("UserStakedData===", UserStakedData);
      setuserstakedata(UserStakedData);
    } catch (error) {
      console.log("error", error);
    }
  };

  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  useEffect(() => {
    if (account) {
    } else {
      setIsLogin(false);
      setTokenSession(null);
    }
  }, [account]);
  let data = {
    isLogin,
    isLoading,
    errorMsg,
    userstakedata,
    successMSG,
    userData,
    yourWalletBalance,
    adminAddress,
    userDataStatus,
    setDashboardData,
    dashboardData,
    userDetails,
    controlData,
    updateUser: (account) => {
      setSession(account);
    },

    connectWallet: (data) => connectToWallet(data),
    logoutHanlder: () => {
      setUserData();
      setUserDataStatus();
      setIsLogin(false);
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("walletName");
      deactivate();
    },
  };

  const getUserbalce = async () => {
    var web3 = new Web3(library.provider);
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = await web3.utils.fromWei(balance);
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2));
  };
  useEffect(() => {
    if (account) {
      getUserbalce();
    }
  }, [account, library]);

  const connectToWallet = (data) => {
    if (data) {
      try {
        const connector = data.connector;

        if (connector && connector.walletConnectProvider?.wc?.uri) {
          connector.walletConnectProvider = undefined;
        }

        activate(connector, undefined, true).catch((error) => {
          if (error) {
            window.sessionStorage.removeItem("walletName");
            toast.error(JSON.stringify(error.message));
            window.sessionStorage.removeItem("walletName");
            activate(connector);
          }
        });
      } catch (error) {
        toast.error(JSON.stringify(error.message));
      }
    }
  };
  const connecting = async (id) => {
    try {
      const res = await axios({
        method: "POST",
        url: ApiConfig.connectwalletapi,
        data: {
          walletAddress: id,
        },
      });

      console.log("sfjsdfj", res);
      if (res.status === 200) {
        setUserDataStatus(res.data.result);
        console.log("lskfjlks", res);
        console.log("lskdjflks", res.data.result.token);
        data.updatetoken(res.data.result.token);

        // myprofile();
      } else {
        console.log("error");
        setIsLogin(false);
      }
    } catch (error) {
      setIsLogin(false);
      console.log(error.message);
    }
  };
  useEffect(() => {
    data.updateUser(account);
    if (account) {
      connecting(account);
    } else {
      setIsLogin(false);
    }
  }, [account]);
  useEffect(() => {
    if (window.sessionStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name === window.sessionStorage.getItem("walletName")
      );
      if (selectectWalletDetails[0]) {
        connectToWallet(selectectWalletDetails[0].data);
      }
    }
  }, []);

  useEffect(() => {
    if (account && chainId) {
      if (chainId != ACTIVE_NETWORK) {
        window.scrollTo(0, 0);
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };
  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
