import tinycolor from "tinycolor2";
const primary = "#fafafa";
const secondary = "#FF5C93";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#9013FE";
const lightenRate = 7.5;
const darkenRate = 15;
const defaultTheme = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: "#FFFFFF",
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9",
    },

    background: {
      default: "#F6F7FF",
      light: "#F3F5FF",
    },
  },
  overrides: {
    MuiBox: {
      root: {
        padding: "0px",
      },
    },
    // MuiDialog: {
    //   paperWidthSm: {
    //     width: "50%",
    //   },
    // },
    MuiDialogActions: {
      root: {
        display: "initial",
        justifyContent: "space-between",
      },
    },
    // MuiButton: {
    //   label: {
    //     fontSize: "12px",
    //   },
    //   root: {
    //     fontSize: "0.8rem",
    //   },
    // },
    // MuiButton: {
    //   label: {
    //     fontSize: "14px",
    //   },
    // },
    MuiSwitch: {
      colorSecondary: {
        "&.Mui-checked": {
          color: "#0dd0ff !important",
        },
        "& .Mui-checked + .MuiSwitch-track": {
          backgroundColor: "#0dd0ff !important",
        },
      },
    },
    MuiAccordion: {
      root: {
        "&.Mui-expanded": {
          marginTop: "20px !important",
        },
      },
    },
    MuiToggleButtonGroup: {
      root: {
        width: "100% !important",
        display: "flex !important",
        "& .Mui-selected": {
          color: "rgb(255 255 255) !important",
          backgroundColor: "rgb(16 35 58) !important",
        },
        "& button": {
          width: "25%",
          padding: "5px",
          color: "#10233a",
          borderColor: "#10233a",
          borderStyle: "dashed",
        },
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "none",
      },
      paperAnchorLeft: {
        // /* left: 0; */
        left: "0",
        right: "auto",
      },
    },
    MuiStepIcon: {
      text: {
        fill: "#090b16",
      },
      root: {
        color: "rgb(255 255 255 / 38%)",
        MuiStepIconActive: {
          color: "#fff",
        },
        "&.MuiStepIcon-completed": {
          color: "#fabe25",
        },
        "&.MuiStepIcon-active": {
          color: "#fabe25",
        },
      },
    },
    MuiTableRow: {
      root: {
        color: "inherit",
        display: "table-row",
        outline: "0",
        verticalAlign: "middle",
        background: "#23252e",
      },
    },
    MuiTableCell: {
      body: {
        color: "#fff",
      },
      root: {
        display: "table-cell",
        padding: "13px",
        fontSize: "0.875rem",
        textAlign: "left",
        fontWeight: "400",
        lineHeight: "1.43",
        borderBottom: "1px solid #191822",
        letterSpacing: "0.01071em",
        verticalAlign: "inherit",
      },
    },

    MuiDialog: {
      paperWidthSm: {
        width: "500px",
        // height: "45rem",
      }, //   paperWidthSm: {
      //     width: "50%",
      //   },
    },

    MuiTab: {
      root: {
        padding: "6px 12px",
        overflow: "hidden",
        position: "relative",
        fontSize: "16px",
        fontWeight: "bold",
        maxWidth: "264px",
        minWidth: "72px",
        boxSizing: "border-box",
        minHeight: "48px",
        textAlign: "center",
        lineHeight: "1.75",
        whiteSpace: "normal",
        letterSpacing: "0.02857em",
        textTransform: "uppercase",
        "@media (max-width: 767px)": {
          fontSize: "13px",
        },
      },
    },
    MuiSlider: {
      root: {
        color: "#f6a52d",
        width: "100%",
        cursor: "pointer",
        height: "8px",
        display: "inline-block",
        padding: "13px 0",
        position: "relative",
        boxSizing: "content-box",
        touchAction: "none",
      },
    },
    MuiInputBase: {
      borderRadius: "10px",
      input: {
        font: "inherit",
        color: "#fff",
        // border: "1px solid #8a8b8b",
        width: "100%",
        borderRadius: "10px",
        fontSize: "13px",
        height: "0.1876em",
        margin: "0",
        display: "block",
        padding: "6px 0 7px",
        minWidth: "0",
        background: "none",
        boxSizing: "content-box",
        letterSpacing: "inherit",
        "&:focus-visible": {
          // outline: "none",
        },
      },
    },

    MuiSvgIcon: {
      root: {
        color: "#31B0E3",
        height: "1em",
        display: "inline-block",
        transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        flexShrink: "0",
        userSelect: "none",

        // marginRight: "7px",
      },
    },
    MuiAppBar: {
      root: {
        width: "100%",
        display: "flex",
        boxSizing: "border-box",
        flexShrink: "0",
      },
      colorPrimary: {
        color: "#fff",
        backgroundColor: "transparent",
      },
    },
    MuiMenuItem: {
      root: { color: "#fff", padding: "6px" },
    },
    MuiPaper: {
      root: { boxShadow: "none !important" },
      rounded: {
        backgroundColor: " #111214",
        // width: "100%",
        // border: "1px solid #f6a52d8f",
        borderRadius: "14px",
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: " #090b16",
        border: "1px solid #443309",
        "@media (max-width: 767px)": {
          display: "block",
        },
      },
    },
    MuiStep: {
      horizontal: {
        width: "25%",
        "@media (max-width: 767px)": {
          width: "100%",
        },
      },
    },
    MuiOutlinedInput: {
      adornedEnd: {
        paddingRight: "-3px",
      },
    },
    MuiButton: {
      outlinedSizeLarge: {
        padding: "7px 21px",
        width: "100%",
        maxWidth: "140px",
        height: "45px",
      },
      label: {
        fontSize: "12px",
      },
      // root: {
      //   fontSize: "0.8rem",
      // },
      containedSizeLarge: {
        padding: "8px 22px",
        fontSize: "-4.0625rem",
        "@media (max-width: 767px)": {
          fontSize: "10px",
        },
      },
      containedSecondary: {
        color: "#FFFFFF",
        border: "3px solid #E8424C",
        cursor: "pointer",
        height: "50px",
        fontSize: "14px",
        minWidth: "143px",
        backgroundColor: "#E8424C",
        boxShadow: "none",
        fontWeight: "600",
        borderRadius: "10px",
        "&:hover": {
          borderColor: "#E8424C",
          backgroundColor: "#E8424C",
        },
      },
      outlinedPrimary: {
        color: " #ffffff",
        background: "linear-gradient(180deg, #4CB3DC 0%, #4CB3DC 100%)",
        border: "3px solid #4CB3DC",
        borderRadius: "10px",
        fontSize: "14px !important",
        lineHeight: "16px",
        height: " 50px",
        fontWeight: "600",
        maxWidth: "153px",

        "&:hover": {
          background: "#FFFFFF !important",
          border: "3px solid #FFFFFF",
          color: "#392472",
        },
      },
      root: {
        // "&:hover": {
        //   backgroundColor:
        //     "linear-gradient(180deg, rgba(248,185,0,1) 16%, rgba(143,84,9,1) 79%)",
        // },
        "&.Mui-disabled": {
          color: "rgba(255, 255, 255, 0.7) !important",
          background: "#4acaf84f !important",
        },
      },
      contained: {
        "&:hover": {
          backgroundColor: "linear-gradient(180deg, #F8B900 0%, #8F5409 100%)",
        },
        "&.Mui-disabled": {
          color: "rgb(219 219 219 / 48%)",
        },
      },
      containedPrimary: {
        color: "#fff",
        border: "2px solid transparent",
        cursor: "pointer",
        height: "44px",
        fontSize: "14px",
        minWidth: "125px",
        background:
          "linear-gradient(272.26deg, #0DD0FF 36.78%, #05A4CA 86.13%)",
        boxShadow: "inset 0px 0px 2px rgb(0 0 0 / 40%)",
        fontWeight: "600",
        borderRadius: "10px",
        "&:hover": {
          color: "#fff",
          background: "#0c2739",

          border: "2px solid transparent",
          boxSizing: "border-box",
          backgroundClip: "padding-box, border-box",
          backgroundImage:
            "linear-gradient(#0c2739, #0c2739), linear-gradient(#2599fa, #1dcbfa)",
          backgroundOrigin: "padding-box, border-box",
        },
        "&.active": {
          background: "linear-gradient( 180deg, #8F5409 0%, #F8B900 100%)",
        },
        "@media (max-width: 1024px)": {
          fontSize: "13px",
        },
        "@media (max-width: 767px)": {
          fontSize: "13px",
          // width: "100%",
          // marginTop:"10px",
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      },
    },
    MuiSelect: {
      selectMenu: {
        // border: "1px solid #8a8b8b",
        width: "100%",
        borderRadius: "5px",
        fontSize: "13px",
        // height: "0.1876em",
        margin: "0",
        display: "block",
        padding: "10px 12px 7px",
        height: "23px",
        display: "flex",
      },
      select: {
        "&:focus": {
          borderRadius: "5px",
        },
      },
      icon: {
        color: "#B9B9B9",
        top: "calc(50% - 14px)",
      },
      iconOutlined: {
        right: "4px",
      },
    },
    PrivateSwitchBase: {
      root: {
        marginLeft: 0,
      },
    },
  },
};

export default defaultTheme;
