import {
  Container,
  Box,
  Typography,
  Grid,
  makeStyles,
  TextField,
  Button,
  ListItem,
  List,
  Hidden,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import { Facebook, TextFields } from "@material-ui/icons";
import React, { useState } from "react";
import { AiFillMediumCircle, AiFillWechat } from "react-icons/ai";
import { ImFacebook } from "react-icons/im";
import {
  FaDiscord,
  FaFacebook,
  FaTelegramPlane,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";

export function isValidEmail(value) {
  console.log("helllooooooooooooo");
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
  return re.test(String(value).toLowerCase());
}
const useStyles = makeStyles((theme) => ({

  sate: {
    paddingTop: "30px",
    paddingBottom: "40px",
    textAlign: "center",
  },
  mainBox1: {
    paddingTop: "50px",
    "& .MenuItem": {
      "& h4": {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "21px",
        paddingBottom: "15px",
      },
      "& .listingFooter1": {
        "& .MuiListItem-gutters": {
          padding: "0",
          textDecoration: "none",
          fontFamily: "Roboto",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "16px",
          color: "#939393",
        },
      },
    },
    "& .input1": {
      background: "#1A1A1A",
      borderRadius: "10px",
      marginLeft: "5px",
      width: "279px",

      "@media(max-width:960px)  ": {
        width: "220px",
      },
    },
    "& .listingFooter": {
      "& a": {
        textDecoration: "none",
      },
      "& svg": {
        padding: "5px",
        color: "#fafafa",
        background: "#39362F",
        // height: "40px",
        // width: "40px",
        borderRadius: "50%",
      },
      "& .MuiListItem-gutters": {
        padding: "0",
        width: "35px",
      },
      display: "flex",
    },
    "& h3": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      color: "#FFFFFF",
      fontWeight: 700,
      fontSize: "30px",
      lineHeight: "80px",

      "@media(max-width:1024px)  ": {
        fontSize: "28px",
        lineHeight: "70px",
        // letterSpacing: "0.4em",
      },
      "@media(max-width:960px)  ": {
        fontSize: "25px",
        lineHeight: "50px",
        fontWeight: 500,
        // letterSpacing: "0.4em",
      },
      "@media(max-width:425px)  ": {
        fontSize: "20px",
        lineHeight: "50px",
        fontWeight: 500,
        // letterSpacing: "0.4em",
      },
    },

    "& :hover": {
      "& .MuiInputBase-root": {
        borderRadius: "10px",
        // border: "2px solid #fff",
      },
    },
  },

  createbutton: {
    fontSize: "14px ",
    fontWeight: 700,
    height: "44px ",
    background: "linear-gradient(280deg,#78FDFF 0%, #1D84C7 100%)",
    color: "#fff",
    borderRadius: "10px",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: "45px ",
      minWidth: "120px ",
    },
    "&:hover": {
      color: "#fff",
      background: "#1D84C7",
      transition: "none",
    },
  },
}));

export default function NewFooter1() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [isSubmit, setSubmit] = useState(false);
  const ContactUs = () => {
    setSubmit(true);
    console.log("Email ");
    if (email !== "" && isValidEmail(email)) {
      setSubmit(false);
      setEmail("");
      console.log("Email Verified");
      console.log("Email Verified", isValidEmail(email));
    }
  };
  console.log(isSubmit);
  console.log("es==---", isValidEmail(email));
  return (
    <Box className={classes.mainBox1}>
      <Container>
        <Grid container spacing={4}>
          <Grid item lg={6} sm={3} md={5} xs={12}>
            {/* <img src="images/footerLogo.svg" /> */}
            <Typography variant="h3">GETTING STARTED IS EASY!</Typography>
            {/* <Box mr={1}> */}
            <FormControl>
              <TextField
                className="input1"
                style={{
                  fontSize: "26px",
                  fontWeight: "500",
                }}
                id="outlined-basic"
                variant="outlined"
                outlined="none"
                placeholder="example@email.com"
                type="email"
                min={1}
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                error={
                  (isSubmit && email == "") ||
                  (isSubmit && !isValidEmail(email))
                }
              // value={lockPriceByPer}
              />
              <FormHelperText error>
                {isSubmit && email == ""
                  ? "email is required"
                  : isSubmit && !isValidEmail(email)
                    ? "enter a valid email"
                    : null}
              </FormHelperText>
            </FormControl>
            {/* </Box> */}
            <Button
              variant="contained"
              size="large"
              style={{
                whiteSpace: "nowrap",
                marginLeft: "5px",
              }}
              className={classes.createbutton}
              onClick={ContactUs}
            >
              JOIN FOR FREE
            </Button>
            <Box style={{ paddingTop: "20px" }}>
              <List className="listingFooter">
                <ListItem>
                  {" "}
                  <a href="https://twitter.com/home" target="_blank">
                    {" "}
                    <FaTwitter style={{ fontSize: "30px" }} />
                  </a>{" "}
                </ListItem>
                <ListItem>
                  {" "}
                  <a href="https://www.facebook.com/" target="_blank">
                    {" "}
                    <ImFacebook style={{ fontSize: "30px" }} />
                  </a>{" "}
                </ListItem>
                <ListItem>
                  {" "}
                  <a href="https://discord.com/" target="_blank">
                    {" "}
                    <FaDiscord style={{ fontSize: "30px" }} />
                  </a>{" "}
                </ListItem>
                <ListItem>
                  {" "}
                  <a href="https://telegram.org/" target="_blank">
                    {" "}
                    <FaTelegramPlane style={{ fontSize: "30px" }} />
                  </a>{" "}
                </ListItem>
                <ListItem>
                  {" "}
                  <a href="https://www.wechat.com/" target="_blank">
                    {" "}
                    <AiFillWechat style={{ fontSize: "30px" }} />
                  </a>{" "}
                </ListItem>
                <ListItem>
                  {" "}
                  <a href="https://youtube.com/" target="_blank">
                    {" "}
                    <FaYoutube style={{ fontSize: "30px" }} />
                  </a>{" "}
                </ListItem>
              </List>{" "}
            </Box>
          </Grid>
          <Grid item lg={6} sm={9} md={7} xs={12}>
            <Box
              style={{ display: "flex", justifyContent: "space-between" }}
              className="MenuItem"
            >
              <Grid container spacing={1}>
                {/* <Grid item xs={6} sm={4} md={5}>
                  <Typography
                    // className="footerTitle"
                    variant="h4"
                    style={{ color: "#fff" }}
                  >
                    Company
                  </Typography>
                  <List className="listingFooter1">
                    <ListItem to="/" component={Link}>
                      About
                    </ListItem>
                  </List>{" "}
                  <List className="listingFooter1">
                    <ListItem to="/" component={Link}>
                      White Paper
                    </ListItem>
                  </List>{" "}
                </Grid> */}
                {/* <Grid item xs={6} sm={4} md={5}>
                  <Typography
                    className="footerTitle"
                    variant="h4"
                    style={{ color: "#fff" }}
                  >
                    Help & Support
                  </Typography>
                  <List className="listingFooter1">
                    <ListItem to="/" component={Link}>
                      Support
                    </ListItem>
                  </List>{" "}
                  <List className="listingFooter1">
                    <ListItem to="/" component={Link}>
                      FAQ
                    </ListItem>
                  </List>{" "}
                  <List className="listingFooter1">
                    <ListItem to="/" component={Link}>
                      NFT Mint Guide
                    </ListItem>
                  </List>{" "}
                </Grid> */}
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    className="footerTitle"
                    variant="h4"
                    style={{ color: "#fff" }}
                  >
                    {/* Tool */}
                  </Typography>
                  {/* <List className="listingFooter1">
                    <ListItem to="/" component={Link}>
                      Whitepaper
                    </ListItem>
                  </List>{" "} */}
                  <Grid container>
                    <Grid item xs={12} sm={6} md={6}>
                      {/* <List className="listingFooter1">
                        <ListItem
                        // to="/" component={Link} 
                        >
                          Home
                        </ListItem>
                      </List>{" "} */}
                      <Button component={Link} variant="text" color="primary" href="https://landing-metaverse.mobiloitte.org/" target="_blank" size="large">Home</Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      {/* <List className="listingFooter1">
                        <ListItem
                        // component={Link}
                        // href="images/tarality-metaverse.pdf"
                        // target="_blank"
                        // _download
                        > */}
                      {/* <Button
                            component={Link}
                            href="images/tarality-metaverse.pdf"
                            target="_blank"
                            _download
                          >
                            Litepaper
                          </Button> */}
                      <Button
                        // component={Link}
                        variant="text" color="primary" href="images/tarality-metaverse.pdf" target="_blank" size="large"
                        _download>Read Litepaper</Button>
                      {/* </ListItem> 
                    </List>{" "}*/}
                    </Grid>
                  </Grid>
                  {/* <List className="listingFooter1">
                    <ListItem to="/" component={Link}>
                      Roadmap
                    </ListItem>
                  </List>{" "}
                  <List className="listingFooter1">
                    <ListItem to="/" component={Link}>
                      Mint
                    </ListItem>
                  </List>{" "} */}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {/* <Hidden smDown> */}
        <Box textAlign="center" className={classes.sate}>
          {/* <Typography variant="h1">TARALITY</Typography> */}
          <Container maxWidth="md">
            <img
              src="images/TARALITY.png"
              alt="Stake Banner"
              style={{ width: "100%", height: "100%" }}
            />
          </Container>
        </Box>
        {/* </Hidden> */}
      </Container >
    </Box >
  );
}
