import React, { useState, useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  useLocation,
} from "react-router-dom";
import classnames from "classnames";
import { makeStyles, Box } from "@material-ui/core";
//icons

import Header from "../Header";
import Sidebar from "../SideBar/SideBar";

// pages
import Maindashboard from "../../pages/maindashboard/Maindashboard";
import Network from "../../pages/maindashboard/Network";
import Buycoin from "../../pages/maindashboard/Buycoin";
import WhitelistUserList from "../../pages/maindashboard/WhitelistUserList";

import { useLayoutState } from "../../context/LayoutContext";
import Footer from "./Footer";
// import HowitWorks from "../../pages/Howitworks";

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    // maxWidth: "100vw",
    // overflowX: "hidden",
    backgroundImage: "url('../images/roadmap.png')",
  },
  content: {
    flexGrow: 1,
    // padding: "24px 100px 0",
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    minHeight: "100vh",
    paddingTop: "80px",
    zIndex: "1",
    overflow: "hidden",

    position: "relative",
    "@media (max-width:1440px)": {
      padding: "24px 30px 0",
    },
    [theme.breakpoints.down("md")]: {
      padding: "24px 24px",
      paddingTop: "80px",
    },
  },
  contentShift: {
    // backgroundImage: "url('../images/banner.png')",
    background:
      "radial-gradient(48.06% 27.42% at 84.2% 48.18%, rgba(32, 104, 188, 0.45) 0%, rgba(52, 107, 171, 0) 100%) , radial-gradient(39.41% 25.47% at 12.19% 49%, rgb(193 215 224 / 45%) 0%, rgba(221, 237, 254, 0.45) 100%) , #FFFFFF",
    zIndex: " 1",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    // backgroundAttachment: "fixed",
    width: `calc(99vw - 225px)`,
    position: "absolute",
    top: 0,
    padding: 0,
    right: "0",
    [theme.breakpoints.down("sm")]: {
      width: `100%`,
    },
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  link: {
    "&:not(:first-child)": {
      paddingLeft: 15,
    },
  },

  buttonright: {
    background: "linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)",
    fontSize: "14px ",
    border: "1px solid transparent",
    fontWeight: 600,
    height: "44px ",
    color: "#FFFFFF",
    minWidth: "135px ",
    borderRadius: "5px",
    boxShadow: "none ",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: "45px ",
      minWidth: "120px ",
    },
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      color: "#fff",
      transition: "0.5s",
      boxSizing: " border-box",
      border: " 2px solid transparent",
      backgroundClip: "padding-box, border-box",
      backgroundOrigin: "padding-box, border-box",
      backgroundImage:
        "linear-gradient(#000, #000), linear-gradient(#2599fa, #42E8E0)",
    },
  },
  mainBodyBox: {
    // height: "100vh",
    // paddingBottom: "100px",
    padding: "0 0",
    minHeight: "87vh",
    // backgroundImage: "url('../images/stakingBg.svg')",
    // "@media(maxWidth:1280px)": {
    //   minHeight: "calc(100vh - 145px)",
    // },
  },
}));
function Layout(props) {
  const classes = useStyles();
  const location = useLocation();
  const [checkLayout, setCheckLayout] = useState(false);
  // console.log("URL====", window.location.href);
  useEffect(() => {
    const checkUrl = window.location.href.split("#");
    // console.log("locationCheck*********", checkUrl);
    if (checkUrl[1] === "/app/home") {
      setCheckLayout(true);
    } else {
      setCheckLayout(false);
    }
  }, [window.location.href]);

  var layoutState = useLayoutState();
  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <div>
          <Box className={classes.mainBodyBox}>
            <Switch>
              Staking
              <Route path="/app/stake-dashboard" component={Maindashboard} />
              <Route path="/app/Network" component={Network} />
              <Route path="/app/Buycoin" component={Buycoin} />
              <Route
                path="/app/WhitelistUserList"
                component={WhitelistUserList}
              />
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
            </Switch>
          </Box>

          <Footer />
        </div>
      </>
      {/* )} */}
    </div>
  );
}

export default withRouter(Layout);
