import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  Button,
  TextField,
  IconButton,
  Paper,
  InputAdornment,
  FormHelperText,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
} from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { makeStyles, withStyles } from "@material-ui/styles";
import Slider from "@material-ui/core/Slider";
import { toast } from "react-toastify";
import { CgClose } from "react-icons/cg";
import Faqs from "./Faqs";
import Network from "./Network";
import {
  NewStakingContracAddress,
  default_RPC_URL,
} from "../../constants/index";
import KingShibaStakingABI from "../../abis/KingShibaStakingABI.json";
import IERC20ABI from "../../abis/IERC20ABI.json";
import HowItsWork from "./HowItsWork";
import BuyStaking from "./BuyStaking";
import {
  fromWeiDecimals,
  getContract,
  getWeb3ContractObject,
  getWeb3Obj,
} from "../../utils";
import Web3 from "web3";
import multicall from "../../utils/multicall";
import axios from "axios";
import { UserContext } from "../../context/User";
import ConnectWallet from "../../components/ConnectWalletPopUp";
import { Form, Formik } from "formik";
import * as yep from "yup";
import ButtonCircularProgress from "../../components/Loaders/ButtonCircularProgress";
// import { FormControl } from "@material-ui/core/FormControl";
import ApiConfig from "../../../src/config/ApiConfig";

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 25,
    label: "25%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 75,
    label: "75%",
  },
  {
    value: 100,
    label: "100%",
  },
];

const useStyles = makeStyles((theme) => ({
  textbox: {
    marginTop: "40px",
    marginBottom: "40px",
    "& h2": {
      // fontWeight: "bold",
      // lineHeight: "55px",
      // color: "#000",
      // fontSize: "38px",
      // [theme.breakpoints.down("xs")]: {
      //   fontSize: "30px",
      // },

      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "23px",
      textAlign: "start",

      color: "#9D9FB1",
    },
  },
  textbox1: {
    marginTop: "30px",
    marginBottom: "10px",
    paddingLeft: "20px",
    textAlign: "start",
    "& h2": {
      fontFamily: "Impact",
      fontWeight: "400",
      lineHeight: "36px",
      marginBottom: "20px",
      color: "#ffffff",
      fontSize: "30px",
      letterSpacing: "0.2em",
      // [theme.breakpoints.down("xs")]: {
      //   fontSize: "30px",
      // },
    },
    "& p": {
      fontWeight: "400",
      paddingTop: "8px",
      fontFamily: "Roboto",
      lineHeight: "30px",
      color: "#ffffff",
      fontSize: "14px",
      maxWidth: "600px",
      // [theme.breakpoints.down("xs")]: {
      //  fontSize: "30px",
      // },
    },
    "& h4": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "26px",
      color: "#fff",
      marginBottom: "10px",
      maxWidth: "600px",
    },

    // "&::after": {
    // content: "''",
    // position: "absolute",
    top: "30%",
    left: "4%",
    width: "100%",
    height: "100%",
    // backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 1,
    // },
  },
  bannerBox1: {
    // paddingTop: "86px",
    backgroundImage: "url(./images/bannerStake.png)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    marginTop: "50px",
    padding: "70px 0px 50px",
    backgroundRepeat: "no-repeat",
    // position: "relative",
  },
  textFeild: {
    backgroundColor: "#000",
    color: "#fff",
    // margin: "15px ",
    width: "100%",
    borderRadius: "10px",
  },
  textFeild1: {
    paddingTop: "20px",
  },
  bannerBox: {
    paddingBottom: "45px",
    "& .MuiSlider-markLabel ": {
      color: "#9D9FB1",
    },
    "& .progress": {
      "& .top": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px 0px",
        "& p": {
          color: "#000000",
          fontWeight: "300",
        },
        "& div": {
          display: "flex",
          alignItems: "center",
          "& h6": {
            paddingLeft: "15px",
            color: "#89d5f5",
            fontWeight: "600",
          },
          "& p": {
            color: "#000000",
          },
        },
      },
      "& .bottom": {
        display: "flex",
        justifyContent: "space-between",
        padding: "5px 0px 15px",
        "& h6": {
          color: "#89d5f5",
          fontWeight: "700",
          fontSize: "14px",
        },
      },
    },
    "& .data-daly1": {
      width: "110px",
      height: "97px",
      // left: "763px",
      // top: "1075px",
      background: "#151313",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "& h4": {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: " 16px",
        textAlign: "center",
        color: "#FFFFFF",
      },
      "& h6": {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "14px",
        texAign: "center",
        color: "#9D9FB1",
      },
    },
    "& .data-daly2": {
      width: "110px",
      height: "97px",
      // left: "763px",
      // top: "1075px",
      // background: "#151313",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "& h4": {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: " 16px",
        textAlign: "end",
        color: "#FFFFFF",
      },
      "& h6": {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "14px",
        texAign: "center",
        color: "#9D9FB1",
      },
    },
    "& .root1": {
      backgroundColor: "#89d5f5",
      "& h1": {
        color: "#fff",
      },
      "& h6": {
        color: "#fff",
      },
    },
    "& .root11": { backgroundColor: "#29555B" },
    "& .root12": { backgroundColor: "#2D3E5F" },
    "& .root13": { backgroundColor: "#37265A" },
    "& .root14": { backgroundColor: "#223055" },
    "& .root": {
      height: "147px",

      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "& h6": {
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: "600",
        color: "#9D9FB1",
      },
      "& h4": {
        fontSize: "22px",
        fontWeight: "600",
        color: "#d8e6f7a8",
        marginBottom: "5px",
      },
      "& h1": {
        fontSize: "26px",
        lineHeight: "32px",
        letterSpacing: "0.1em",
        fontWeight: "400",
        color: "#fafafa",
        paddingBottom: "15px",
      },
      "& p": {
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    "& .stake-box": {
      height: "100%",
      padding: "15px 30px",
      backgroundColor: "#1D1C1C",
      "& .stakeData1": {
        paddingTop: "10px",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fonteight: "600",
        fontSize: "14px",
        lineHeight: "16px",
        textAlign: "start",
        color: "#fafafa",
      },
      "& .stakeData": {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fonteight: "400",
        fontSize: "12px",
        lineHeight: "14px",
        textAlign: "end",
        color: "#9D9FB1",
      },
      "& h6": {
        fontSize: "18px",
        fontWeight: "600",
        color: "#89d5f5",
        paddingBottom: "7px",
      },
      "& h4": {
        fontSize: "20px",
        fontWeight: "600",
        color: "#fafafa",
        marginBottom: "5px",
      },
      "& h3": {
        fontSize: "20px",
        fontWeight: 700,
        textAlign: "left",
        // marginBottom: "16px",
        lineHeight: "23px",
        color: "#fafafa",
        paddingBottom: "10px",
      },
      "& .stakInput": {
        "& .input1": {
          background: "#404040",
          borderRadius: "10px",
        },
        "& .maxBtn": {
          // borderLeft: "1px solid #fff",
          backgroundColor: "transperent",
          color: "#fff",
          marginLeft: "10px",
          marginRight: "-5px",
        },
      },
    },
    "& .stake-box11": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "179px",
      padding: "15px",
      backgroundColor: "#1D1C1C",
      "& h6": {
        fontFamily: "Roboto",
        fonteight: 600,
        fontSize: "20x",
        lineHeight: "23px",
        textAlign: "center",
        color: "#fafafa",
      },
      "& h4": {
        fontFamily: "Roboto",
        fontWight: 400,
        fontSize: "16px",
        lineHeight: "19px",
        textAlign: "center",
        color: "#9D9FB1",
        paddingBottom: "25px",
      },
      "& h3": {
        fontSize: "20px",
        fontWeight: 700,
        textAlign: "left",
        // marginBottom: "16px",
        lineHeight: "23px",
        color: "#fafafa",
      },
      "& .stakInput": {
        "& .input1": {
          background: "#404040",
          borderRadius: "10px",
        },
        "& .maxBtn": {
          backgroundColor: "transperent",
          color: "#fff",
          marginLeft: "10px",
          marginRight: "-5px",
        },
      },
    },
    "& :hover": {
      "& .MuiInputBase-root": {
        borderRadius: "10px",
        // border: "2px solid #fff",
      },
    },
  },
  createbutton: {
    fontSize: "14px ",
    // border: "2px solid transparent",
    fontWeight: 700,
    height: "44px ",
    background: "linear-gradient(280deg,#78FDFF 0%, #1D84C7 100%)",
    color: "#fff",
    // minWidth: "125px ",
    borderRadius: "10px",
    // boxShadow: "inset 0px 0px 2px rgba(0, 0, 0, 0.4)",
    // boxShadow: "none ",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: "45px ",
      minWidth: "120px ",
    },
    "&:hover": {
      color: "#fff",
      background: "#1D84C7",
      transition: "none",
      // border: "2px solid transparent",
      // boxSizing: "border-box",
      // backgroundClip: "padding-box, border-box",
      // backgroundImage:
      //   "linear-gradient(#0c2739, #0c2739), linear-gradient(#2599fa, #1dcbfa)",
      // backgroundOrigin: "padding-box, border-box",
    },
  },
  // MuiOutlinedInput: {
  //   "& .MuiOutlinedInput-input": {
  //     fontSize: "20px",
  //     fontWeight: 500,
  //   },
  // },
  connectwallet: {
    padding: "15px 0px",
  },
}));

export default function Maindashboard(allWeiValues) {
  //KingShibaStakingABI.json  // NewStakingContracAddress
  const classes = useStyles();
  const user = useContext(UserContext);
  const [popupopen, setpopupopen] = useState(false);
  const { account, library, chainId } = useWeb3React();
  const [updatingButtonName, setupdatingButtonName] = useState("Stake");
  const [updatingButtonName1, setupdatingButtonName1] = useState("Unstake");
  const [stakedAmountMin, setstakedAmountMin] = useState();
  const [APRYearlyS, setAPRYearlyS] = useState();
  const [unstakedAmountMin, setUnstakedAmountMin] = useState();
  const [stakeloader2, setstakeloader2] = useState();
  const [stakeloader, setstakeloader] = useState();
  const [stakeloader1, setstakeloader1] = useState();
  const [unStakeloader, setUnStakeloader] = useState();
  const [pendingRewards, setPendingRewards] = useState();
  const [harvestInterval, setHarvestInterval] = useState();
  const [withdrawInterval, setwithdrawInterval] = useState();
  const [rewad, setrewad] = useState();
  const [rewardsPerBlock, setRewardsPerBlock] = useState(0);
  const [userStakedTokens, setUserStakedTokens] = useState();
  const [nextHarvestUntil, setnextHarvestUntil] = useState();
  const [nextWithdrawUntil, setnextWithdrawUntil] = useState();
  const [balanceOf, setbalanceOf] = useState();
  const [balanceStakeToken, setBalanceStakeToken] = useState(0);
  const [stakeSymbolStakeToken, setstakeSymbolStakeToken] = useState();
  const [rewardSymbolStakeToken, setRewardSymbolStakeToken] = useState();
  const [getStakedData, setGetStakedData] = useState();
  const [userInfos, setUserInfos] = useState();
  const [rewardPerBlockToken, setRewardPerBlockToken] = useState("");
  const [rewardTokenAddress, setrewardTokenAddress] = useState("");
  const [stakeTokenAddress, setstakeTokenAddress] = useState("");
  const [totalPandingReward, setTotalPandingReward] = useState("");
  const [openWalletConnect, setOpenWalletConnect] = React.useState(false);
  const [isLoading, setLoader] = useState(false);

  const httpProvider = new Web3.providers.HttpProvider(default_RPC_URL);
  const web3 = new Web3(httpProvider);
  const userstakebalance = user.userstakedata;
  const [mainsubmit, setmainsubmit] = useState(false);
  console.log("sldfjks", userstakebalance);
  const getCoinBalanceAccount = async () => {
    try {
      // const web3 = (window.web3 = new Web3(window.ethereum));
      // const contractAddress = getStakeAddress(tab);
      const web3 = await getWeb3Obj(default_RPC_URL);

      const calls = [
        {
          address: NewStakingContracAddress,
          name: "owner",
        },
        {
          address: NewStakingContracAddress,
          name: "rewardPerBlock",
        },
        {
          address: NewStakingContracAddress,
          name: "rewardToken",
        },
        {
          address: NewStakingContracAddress,
          name: "pool",
        },
        {
          address: NewStakingContracAddress,
          name: "usersLength",
        },
        {
          address: NewStakingContracAddress,
          name: "totalStaked",
        },
        // {
        //   address: NewStakingContracAddress,
        //   name: "usersLength",
        // },
      ];
      const [
        Owner,
        RewardPerBlock,
        RewardToken,
        Pool,
        UsersLength,
        totalStaked,
        // usersLength,
      ] = await multicall(KingShibaStakingABI, calls, chainId, default_RPC_URL);
      console.log(Owner);
      setrewardTokenAddress(RewardToken);
      setstakeTokenAddress(Pool.stake);
      // console.log("========>>>>>>>>>>>.UsersLength", UsersLength[0].toString());
      const obj = {
        Owner,
        RewardToken,
        Pool,
        withdrawInterval: Pool.withdrawInterval.toString(),
        harvestInterval: Pool.harvestInterval.toString(),
        accTokenPerShare: web3.utils.fromWei(Pool.accTokenPerShare.toString()),
        RewardPerBlock: web3.utils.fromWei(RewardPerBlock[0].toString()),
        UsersLength: UsersLength[0].toString(),
        totalStaked: web3.utils.fromWei(totalStaked[0].toString()),
      };
      console.log("========>>>>>>>>>>>.UsersLength", obj);
      setGetStakedData(obj);
      const contractObj = await getWeb3ContractObject(
        KingShibaStakingABI,
        NewStakingContracAddress,
        default_RPC_URL
      );
      console.log("NewStakingContracAddress", contractObj);
      const StakeercContract = await getWeb3ContractObject(
        IERC20ABI,
        Pool.stake,
        default_RPC_URL
      );
      const ercContract = await getWeb3ContractObject(
        IERC20ABI,
        RewardToken[0],
        default_RPC_URL
      );
      if (account) {
        const balanceOf = await ercContract.methods.balanceOf(account).call();
        const BalanceStakeeToken = await StakeercContract.methods
          .balanceOf(account)
          .call();
        const userInfo = await contractObj.methods.userInfo(account).call();
        console.log("userInfo", userInfo);
        setUserInfos(userInfo);
        setbalanceOf(web3.utils.fromWei(balanceOf.toString()));
        setBalanceStakeToken(web3.utils.fromWei(BalanceStakeeToken.toString()));
      }
      const rewardSymbol = await ercContract.methods.symbol().call();
      setRewardSymbolStakeToken(rewardSymbol);
      const stakeSymbol = await StakeercContract.methods.symbol().call();
      setstakeSymbolStakeToken(stakeSymbol);
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    // console.log(user.isLogin);
    if (account) {
      getCoinBalanceAccount();
      getPoolStakeHandler();
    }
  }, [account]);

  useEffect(() => {
    getCoinBalanceAccount();
    getPoolStakeHandler();
  }, []);

  const getPoolStakeHandler = async () => {
    try {
      const web3 = await getWeb3Obj(default_RPC_URL);

      const contract = await getWeb3ContractObject(
        KingShibaStakingABI,
        NewStakingContracAddress,
        default_RPC_URL
      );
      console.log("contract---", contract);

      const pool1 = await contract.methods.pool().call();
      console.log("pool", pool1);
      const calls = [
        {
          address: NewStakingContracAddress,
          name: "pool",
        },
        {
          address: NewStakingContracAddress,
          name: "rewardPerBlock",
        },
        {
          address: NewStakingContracAddress,
          name: "rewardToken",
        },
        {
          address: NewStakingContracAddress,
          name: "totalStaked",
        },
      ];

      const [poolDetails, rewardPerBlock, rewardToken, totalStaked] =
        await multicall(KingShibaStakingABI, calls, chainId, default_RPC_URL);
      console.log("rewardToken --------- rewardToken ----", rewardToken[0]);

      const RewardsObject = await getWeb3ContractObject(
        IERC20ABI,
        rewardToken[0],
        default_RPC_URL
      );
      const balanceOfRewardsObject = await RewardsObject.methods
        .balanceOf(NewStakingContracAddress)
        .call();
      const DecimalsRewardsObject = await RewardsObject.methods
        .decimals()
        .call();
      console.log(
        DecimalsRewardsObject,
        "rewardToken --------- rewardToken ----'1'----balanceOfRewardsObject",
        fromWeiDecimals(balanceOfRewardsObject, DecimalsRewardsObject)
      );
      setTotalPandingReward(
        fromWeiDecimals(balanceOfRewardsObject, DecimalsRewardsObject)
      );
      console.log("--------------'1'----rewardsPerBlock", rewardPerBlock[0]);
      setHarvestInterval(poolDetails?.harvestInterval.toString());
      setwithdrawInterval(poolDetails?.withdrawInterval.toString());
      const rewads = web3.utils.fromWei(rewardPerBlock.toString());
      setRewardsPerBlock(rewads);

      let totalStakedV = web3.utils.fromWei(totalStaked[0].toString());
      let totalStakedV12 = totalStakedV == 0 ? 1 : totalStakedV;
      let APY123 = (rewads * 28800 * 365) / Number(totalStakedV12);
      let APYinals = totalStakedV == 0 ? 0 : parseFloat(APY123) * 100;

      setrewad(parseFloat(APYinals));
      // setrewad((parseFloat(rewads) * 1000000) / 9.6450617);
      if (account) {
        const pendingRewards = await contract.methods
          .pendingRewards(account)
          .call();
        const pendingRewardsETH = web3.utils.fromWei(pendingRewards.toString());
        console.log("pendingRewardsETH", pendingRewardsETH);
        setPendingRewards(pendingRewardsETH);
        const userInfo = await contract.methods.userInfo(account).call();
        setUserStakedTokens(web3.utils.fromWei(userInfo.amount.toString()));
        setnextHarvestUntil(userInfo.nextHarvestUntil.toString());
        setnextWithdrawUntil(userInfo.nextWithdrawUntil.toString());
        const rewardPerBlock =
          (parseFloat(web3.utils.fromWei(rewads.toString())) * 1000000) /
          9.6450617;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getStakingDataHandler = async () => {
    // console.log("setLiteAmount", setLiteAmount);
    // let stakedAmountMin;

    try {
      setstakeloader(true);
      if (account) {
        const web3 = (window.web3 = new Web3(window.ethereum));

        const contract = getContract(
          NewStakingContracAddress,
          KingShibaStakingABI,
          library,
          account
        );
        console.log("-------------", contract);
        const erc20address = await contract.pool();
        const contract1 = getContract(
          erc20address.stake,
          IERC20ABI,
          library,
          account
        );
        // console.log("setalite", setalite);
        console.log("-------------", contract1);
        console.log("-------------", erc20address);
        // if (setLiteAmount >= setalite[0]?.amount) {
        const amountToSend = web3.utils.toWei(stakedAmountMin.toString());
        const balanceOfValue = await contract1.balanceOf(account);
        const walletBalance = web3.utils.toWei(balanceOfValue.toString());

        if (Number(walletBalance) > stakedAmountMin) {
          setupdatingButtonName("Approving");
          const approveFun = await contract1.approve(
            NewStakingContracAddress,
            amountToSend
          );
          await approveFun.wait();
          setupdatingButtonName("Depositing");
          const ownerFun = await contract.deposit(amountToSend);
          await ownerFun.wait();
          // user.GetTotalStakedFun();
          toast.success("Successfully Staked");

          setupdatingButtonName("Stake");
        } else {
          setstakeloader(false);
          toast.error("Low balance");
          setupdatingButtonName("Stake");
        }
      } else {
        toast.error("Please connect your wallet first!");
        setupdatingButtonName("Stake");
      }
      getPoolStakeHandler();
      setstakeloader(false);
      setupdatingButtonName("Stake");
    } catch (error) {
      setstakeloader(false);
      setupdatingButtonName("Stake");

      toast.error(error.message);
      console.log("error????", error);
    }

    // getAllCoinsData();
    getCoinBalanceAccount();
  };

  const getUnStakingDataHandler = async () => {
    const web3 = await getWeb3Obj();
    if (
      unstakedAmountMin &&
      unstakedAmountMin != "" &&
      Number(unstakedAmountMin) > 0
    ) {
      try {
        if (account) {
          setstakeloader1(true);
          setupdatingButtonName1("withdraw");
          const contract = getContract(
            NewStakingContracAddress,
            KingShibaStakingABI,
            library,
            account
          );
          // const canWithdraw = await contract.canWithdraw(account);

          // if (canWithdraw) {
          const amountToSend = web3.utils.toWei(unstakedAmountMin.toString());
          // console.log("amountToSend", amountToSend);
          const ownerFun = await contract.withdraw(amountToSend);
          await ownerFun.wait();
          // user.GetTotalStakedFun();
          getPoolStakeHandler();
          setstakeloader1(false);
          setupdatingButtonName1("Unstake");
          // } else {
          //   toast.error("Not enough withdraw time");
          //   setstakeloader1(false);
          // }
        } else {
          setUnStakeloader();
          getPoolStakeHandler();
          setstakeloader1(false);
          setupdatingButtonName1("Unstake");
          toast.error("Please connect your wallet first!");
        }
        getPoolStakeHandler();
        setstakeloader1(false);
        setupdatingButtonName1("Unstake");
        // setUnStakeloader();
      } catch (error) {
        setstakeloader1(false);
        setupdatingButtonName1("Unstake");
        // setUnStakeloader();
        console.log(error);
        if (error.data) {
          toast.error(error.data.message);
        } else {
          toast.error(error.message);
        }
      }
    } else {
      toast.error("Please Enter valid data");
    }

    getCoinBalanceAccount();
  };

  const getClaimDataHandler = async () => {
    // if (isActiveSubsription) {
    if (pendingRewards && Number(pendingRewards) > 0) {
      try {
        setstakeloader2(true);
        setUnStakeloader("claim");
        if (account) {
          const contract = getContract(
            NewStakingContracAddress,
            KingShibaStakingABI,
            library,
            account
          );
          const canHarvest = await contract.canHarvest(account);
          if (canHarvest) {
            const ownerFun = await contract.claim();
            await ownerFun.wait();
            toast.success("Rewards Claimed");
            setstakeloader2(false);
          } else {
            toast.error("Not enough claim time");
            getPoolStakeHandler();
            setstakeloader2(false);
          }
        } else {
          // setUnStakeloader();
          toast.error("Please connect your wallet first!");
          setstakeloader2(false);
        }
        getPoolStakeHandler();
        setstakeloader2(false);
        // setUnStakeloader();
      } catch (error) {
        setstakeloader2(false);
        // setUnStakeloader();
        console.log(error);
        if (error.data) {
          toast.error(error.data.message);
        } else {
          toast.error(error.message);
        }
      }
    } else {
      setstakeloader2(false);
      toast.error("Not enough balance to claim");
    }
    getCoinBalanceAccount();
    setstakeloader2(false);
  };

  const [formData, setFormData] = useState(30);

  const [lockPriceByPer, setLockPriceByPer] = useState(0);

  useEffect(() => {
    const weiLockPrice1 =
      (parseInt(formData) / 100) * parseFloat(balanceStakeToken);

    setLockPriceByPer(weiLockPrice1);
  }, [formData, balanceStakeToken, account]);

  /******************************************************** */
  /******************************************************** */
  useEffect(() => {
    if (rewardTokenAddress !== "") {
      myBNBpriceUSD(rewardTokenAddress);
    }
  }, [rewardTokenAddress, account]);
  const [stakeTokenPriceINUsd, setstakeTokenPrice] = useState(0);
  const myBNBpriceUSD = async (stakeTokenAddressId) => {
    axios({
      method: "GET",
      url: `https://api.pancakeswap.info/api/v2/tokens/${stakeTokenAddressId}`,
    })
      .then(async (res) => {
        console.log("stakeTokenPrice", res);
        if (res.status === 200) {
          // console.log("stakingList", res.data.data.);
          setstakeTokenPrice(res.data.data.price);

          // setState(1);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    if (stakeTokenAddress !== "") {
      myRewardTokenPrice(stakeTokenAddress);
    }
  }, [stakeTokenAddress, account]);

  const [rewardTokenPriceINUsd, setrewardTokenPrice] = useState(0);
  const myRewardTokenPrice = async (rewardTokenAddressId) => {
    axios({
      method: "GET",
      url: `https://api.pancakeswap.info/api/v2/tokens/${rewardTokenAddressId}`,
    })
      .then(async (res) => {
        console.log("rewardTokenPrice", res);
        if (res.status === 200) {
          // console.log("stakingList", res.data.data.);
          setrewardTokenPrice(res.data.data.price);

          // setState(1);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  /******************************************************** */
  /******************************************************** */

  const ROICalculateor = async () => {
    try {
      let totalStaked = Number(getStakedData?.totalStaked);
      // token price on pancake swap
      let stakeokenPrice = stakeTokenPriceINUsd
        ? stakeTokenPriceINUsd
        : 0.00001;
      let rewardTokenPrice = rewardTokenPriceINUsd
        ? rewardTokenPriceINUsd
        : 0.00001;
      //
      let rewardPerBlockTokens = Number(rewardsPerBlock) * 28800;
      setRewardPerBlockToken(
        (rewardPerBlockTokens / totalStaked) * lockPriceByPer
      );
      // Total value of reward token
      let totalvalueRewardToken =
        (Number(rewardsPerBlock) * 2600) / Number(totalStaked);
      // Total value of staked token
      let totalValueStakedToken = Number(totalStaked) * Number(stakeokenPrice);
      console.log("totalValueStakedToken", totalValueStakedToken);
      // APR for One year
      let APRYearly = (rewardPerBlockTokens * 365 * lockPriceByPer) / 10000;
      // ((Number(totalvalueRewardToken) * 365) / Number(totalValueStakedToken)) *
      // 100;
      let newAccToken = setAPRYearlyS(APRYearly);
      let LockAccToken =
        Number(lockPriceByPer.toString()) * totalvalueRewardToken;
      console.log("LockAccToken", LockAccToken);
      let devIdeed = Number(LockAccToken) / 1000000;
      console.log("devIdeed", devIdeed);
      let addSub =
        devIdeed -
        fromWeiDecimals(userInfos.rewardDebt.toString(), 18) +
        pendingRewards;
      console.log("addSub", addSub);
      let adaf =
        (Number(lockPriceByPer.toString()) *
          fromWeiDecimals(getStakedData.accTokenPerShare1.toString(), 18)) /
          fromWeiDecimals("1000000000000000000000000", 18) -
        (fromWeiDecimals(userInfos.rewardDebt.toString(), 18) + pendingRewards);

      console.log("aa gya kya jo mene dekha", adaf);
    } catch (error) {
      console.log(error);
    }
  };
  /******************************************************** */
  useEffect(() => {
    ROICalculateor(lockPriceByPer);
  }, [
    lockPriceByPer,
    getStakedData?.totalStaked,
    rewardsPerBlock,
    stakeTokenPriceINUsd,
    rewardTokenPriceINUsd,
    account,
  ]);

  const formValidationSchema = yep.object().shape({
    email: yep
      .string()
      .email("You have entered an invalid email address. Please try again")
      .required("Email address is required")
      .matches("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"),
    // address: yep.string().required("Address is required"),
  });

  const formInitialValue = {
    email: "",
  };

  const validateAccountAddress = async (account) => {
    try {
      const accountCheck = web3.utils.toChecksumAddress(account);
      if (accountCheck !== "") {
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const userstakebalancehandle = (values) => {
    if (userstakebalance >= 250) {
      handleFormSubmit(values);
      setmainsubmit(false);
    } else {
      setmainsubmit(true);
    }
  };

  const handleFormSubmit = async (values) => {
    // const isAccountValid =
    //   values.address !== ""
    //     ? await validateAccountAddress(values.address)
    //     : false;
    // if (isAccountValid) {
    try {
      setLoader(true);
      const res = await axios({
        method: "POST",
        url: ApiConfig.addresswhitelist,
        data: {
          email: values.email,
          walletAddress: account,
        },
      });
      console.log("slfksdfk", res);
      if (res.status === 200) {
        setpopupopen(false);
        setLoader(false);
        toast.success(res.data.responseMessage);
      } else {
        setLoader(false);
        toast.warn(res.data.responseMessage);
      }
    } catch (error) {
      setLoader(false);
      console.log("ksdhfkjs", error);
      toast.success("Network error");
    }
    // } else {
    //   toast.error("Please enter valid address");
    // }
  };

  setTimeout(() => {
    setmainsubmit(false);
  }, 60000);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box></Box>
      <Container maxWidth="lg" align="center">
        {openWalletConnect && (
          <ConnectWallet
            open={openWalletConnect}
            handleClose={() => setOpenWalletConnect(false)}
          />
        )}

        {/* new from here */}
        <Box className={classes.bannerBox1}>
          <Box className={classes.textbox1}>
            <Typography variant="h2">STAKING</Typography>
            <Typography variant="h4">
              Do you own a Rocket NFT? If so, you're in luck – you can now stake
              your Tarality tokens to make satellites! This new staking option
              will allow users to earn Revolution tokens, which can then be used
              for a variety of purposes.
            </Typography>
            <Typography variant="h4">
              So, if you're a Rocket NFT holder, be sure to check out the new
              staking option – it's a great way to earn some extra rewards.
            </Typography>
          </Box>
          <Box>
            {account ? (
              <Button
                variant="contained"
                onClick={() => setpopupopen(true)}
                color="primary"
              >
                Request for whitelist
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => setOpenWalletConnect(true)}
                color="primary"
              >
                Connect Wallet
              </Button>
              // onClick = {() => setOpenWalletConnect(true)}
            )}
          </Box>
        </Box>
        {/* new End here */}
        <Box>
          <HowItsWork />
        </Box>
        <Box>
          <BuyStaking />
        </Box>
        <Box>
          <Faqs />
        </Box>
        {/* <Box>
        <Network />
      </Box> */}
        <Dialog
          open={popupopen}
          onClose={() => setpopupopen(false)}
          disableScrollLock={true}
          className={classes.connectWalletModal}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1px 1px 0px",
            }}
          >
            <Box></Box>
            <Box>
              <IconButton onClick={() => setpopupopen(false)}>
                <CgClose style={{ color: "white", fontSize: "17px" }} />
              </IconButton>
            </Box>
          </Box>
          <Box textAlign="center" style={{ margin: "16px" }}>
            <Typography
              variant="h3"
              style={{ color: "#fff", marginTop: "-40px" }}
            >
              Request for whitelist
            </Typography>
          </Box>
          <DialogContent>
            <Formik
              initialValues={formInitialValue}
              initialStatus={{
                success: false,
                successMsg: "",
              }}
              validationSchema={formValidationSchema}
              onSubmit={(values) => userstakebalancehandle(values)}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                // contact,
                setFieldValue,
              }) => (
                <Form>
                  <Box>
                    <Box>
                      <TextField
                        variant="outlined"
                        placeholder="Enter Your Email ID"
                        fullWidth
                        name="email"
                        type="email"
                        autoComplete="off"
                        value={values.email}
                        className={classes.textFeild}
                        error={Boolean(touched.email && errors.email)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={isLoading}
                      />
                      <FormHelperText
                        error
                        style={{ margin: "0px", fontSize: "12px" }}
                      >
                        {touched.email && errors.email}
                      </FormHelperText>
                    </Box>
                    <Box className={classes.textFeild1}>
                      <TextField
                        className={classes.textFeild}
                        variant="outlined"
                        id="outlined-basic"
                        fullWidth
                        placeholder="Enter your Wallet Address"
                        name="address"
                        // value={values.address}
                        value={account}
                        onChange={handleChange}
                        error={Boolean(touched.address && errors.address)}
                        onBlur={handleBlur}
                        disabled
                      />

                      <FormHelperText error>
                        {touched.address && errors.address}
                      </FormHelperText>

                      {mainsubmit ? (
                        <FormHelperText error>
                          Please stake 250 RVLNG Tokens
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </Box>
                    <Box
                      style={{
                        margin: "26px 16px 26px 16px",
                        textAlign: "center",
                      }}
                    >
                      {/* <Button
                        onClick={() => setpopupopen(false)}
                        variant="contained"
                        color="secondary"
                        disabled={isLoading}
                      >
                        Close
                      </Button> */}
                      <Button
                        type="submit"
                        variant="contained"
                        style={{ marginLeft: "15px" }}
                        color="secondary"
                      >
                        Submit {isLoading && <ButtonCircularProgress />}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </Container>
    </>
  );
}

{
  /* <Box className={classes.bannerBox1}>
        <img
          src="images/bannerStake.png"
          alt="Stake Banner"
          style={{ width: "100%", height: "100%" }}
        />
        <Box className={classes.textbox1}>
          <Grid container>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="h2">STAKING</Typography>
              <Typography variant="body1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Senectus sit tortor, arcu placerat. Venenatis, rhoncus, morbi
                adipiscing aliquam. Eu viverra sit ullamcorper ornare ultrices
                eu varius erat odio. Convallis sed aliquam sit condimentum
                vivamus amet, suscipit.
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}></Grid>
          </Grid>
        </Box>
      </Box> */
}
