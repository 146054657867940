import {
  Box,
  TableCell,
  TableContainer,
  TableHead,
  Table,
  Typography,
  TableBody,
  TableRow,
  IconButton,
  InputAdornment,
  Button,
  FormControl,
  Divider,
  DialogTitle,
  Container,
  Slide,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  makeStyles,
  TextField,
  withStyles,
  Grid,
  MenuItem,
} from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import React, { useState, useEffect, useContext } from "react";
import Page from "../../../src/components/Page";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useHistory, useLocation } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { BsDownload } from "react-icons/bs";
import * as XLSX from "xlsx";
import axios from "axios";
import { UserContext } from "../../../src/context/User";
import { sortAddress } from "../../utils";
import { BiCopy } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import Pagination from "@material-ui/lab/Pagination";
import ButtonCircularProgress from "../../components/Loaders/ButtonCircularProgress";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import ApiConfig from "../../../src/config/ApiConfig";
import TableChild from "./TableChild";
import { getWeb3Obj, getContract, getWeb3ContractObject } from "../../utils";
import { ACTIVE_NETWORK, contractKovan } from "../../constants";
import { useWeb3React } from "@web3-react/core";
import GenerativeNFTABI from "../../abis/GenerativeNFTABI.json";
import Web3 from "web3";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  deviceSection: {
    display: "flex",
    alignItems: "center",
  },

  devicelistHeading: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    // "& h3": {
    //   padding: "1rem 0",
    //   color: theme.palette.text.black,
    // },
    "& .icon1": {
      height: "20px",
      paddingRight: "20px",
      [theme.breakpoints.only("xs")]: {
        width: "50px",
        height: "8px",
        paddingRight: "7px",
      },
    },
    "& .icon2": {
      height: "20px",
      paddingLeft: "20px",
      [theme.breakpoints.only("xs")]: {
        width: "50px",
        height: "8px",
        paddingLeft: "7px",
      },
    },
  },
  activeparent: {
    display: "flex",
    alignItems: "center",
  },
  activeDevice: {
    width: "10px",
    height: "10px",
    background: "green",
    borderRadius: "100px",
  },
  tableRow1: {
    "& td": {
      color: theme.palette.text.black,
    },
    "& th": {
      color: theme.palette.text.black,
    },
  },
  formControl: {
    minWidth: "15vh",
    width: "100%",
  },
  iconcolor: {
    color: "#fff",
  },
  mainbox: {
    // padding: "16px 0 40px",
    paddingTop: "23px",
    "& .tableHead": {
      backgroundColor: "#111214",
      "& th": {
        color: "#fff",
        padding: "11px",
      },
    },
  },
  mainbox1: {
    "& .tableHead": {
      backgroundColor: "#111214",
      "& th": {
        color: "#fff",
        padding: "11px",
      },
    },
  },

  divider: {
    marginTop: "10px",
    backgroundColor: "#F38500",
  },
  deviceSection: {
    display: "flex",
    alignItems: "center",
  },

  devicelistHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h3": {
      //   padding: "1rem 0",
      //   color: theme.palette.text.black,
      color: "#FFFFFF",
      fontSize: "30px",
      fontStyle: "normal",
      fontFamily: "Roboto",
      fontWeight: "700",
      lineHeight: "80px",
    },
    "& .icon1": {
      height: "20px",
      paddingRight: "20px",
      [theme.breakpoints.only("xs")]: {
        width: "50px",
        height: "8px",
        paddingRight: "7px",
      },
    },
    "& .icon2": {
      height: "20px",
      paddingLeft: "20px",
      [theme.breakpoints.only("xs")]: {
        width: "50px",
        height: "8px",
        paddingLeft: "7px",
      },
    },
  },
  activeparent: {
    display: "flex",
    alignItems: "center",
  },
  activeDevice: {
    width: "10px",
    height: "10px",
    background: "green",
    borderRadius: "100px",
  },
  tableRow1: {
    "& td": {
      color: theme.palette.text.black,
    },
    "& th": {
      color: "#fff",
    },
  },
  formControl: {
    minWidth: "15vh",
    width: "100%",
  },
  iconcolor: {
    color: "#fff",
  },
  mainbox: {
    padding: "94px 0px 0px",

    "& .tableHead": {
      backgroundColor: "#111214",
      "& th": {
        color: "#fff",
        padding: "11px",
      },
    },
  },
  mainbox1: {
    "& .tableHead": {
      backgroundColor: "#111214",
      "& th": {
        // color: "black",
        padding: "11px",
      },
    },
  },
  filterBox: {
    color: "#fff",
    position: "relative",
    "& h6": {
      position: "absolute",
      top: "-20px",
      background: theme.palette.background.dark1,

      padding: "5px 10px",
      left: "0px",
      fontSize: "19px",
      color: "#5a86ff",
      fontWeight: 600,
    },
    "& input": {
      color: theme.palette.text.black,
    },
    "& label": {
      //   color: "black",
      fontSize: "14px",
      fontWeight: "400",
      // fontFamily: "'Roboto',sans-serif",
    },
    "& .iconbtn": {
      color: theme.palette.text.black,
    },
  },
  filterLine: {
    marginRight: "10px",
    width: "180px",
    [theme.breakpoints.only("xs")]: {
      width: "145px",
    },
  },
  inputFeild: {
    border: "1px solid #F38500 !important",
    padding: "6px 0px",

    color: "#fff",
    borderRadius: "5px",

    "& svg": {
      color: "#F38500",
      fontSize: "16px",
    },
  },
  textFeild: {
    backgroundColor: "#000",
    color: "#fff",
    // margin: "15px ",
    width: "100%",
    borderRadius: "10px",
    boxShadow: " 1px 0px 0px 6px #111214",
    width: "340px",
  },
  marginsec: {
    "@media(max-width:400px)": {
      marginTop: "20px",
    },
  },
  rootss: {
    display: "flex",
    justifyContent: "end",
    position: "absolute",
    right: "12px",
    marginTop: "5px",
  },
  walletbox: {
    padding: "11px 12px",
    borderRadius: "10px",
    border: "3.5px solid #191942",
    margin: "50px 0px",
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      //   backgroundColor: "#ffdead87",
    },
  },
}))(TableRow);

const tabledetails = [
  {
    id: "User001",
    name: "user",
    state: "Delhi",
    district: "New Delhi",
    datetime: "Jun 20, 2022,7:11:58",
    status: "Active",
  },
  {
    id: "User002",
    name: "user",
    state: "Delhi",
    district: "New Delhi",
    datetime: "Jun 20, 2022,7:11:58",
    status: "Active",
  },
  {
    id: "User003",
    name: "user",
    state: "Delhi",
    district: "New Delhi",
    datetime: "Jun 20, 2022,7:11:58",
    status: "Active",
  },
  {
    id: "User004",
    name: "user",
    state: "Delhi",
    district: "New Delhi",
    datetime: "Jun 20, 2022,7:11:58",
    status: "Active",
  },
  {
    id: "User005",
    name: "user",
    state: "Delhi",
    district: "New Delhi",
    datetime: "Jun 20, 2022,7:11:58",
    status: "Active",
  },
  {
    id: "User006",
    name: "user",
    state: "Delhi",
    district: "New Delhi",
    datetime: "Jun 20, 2022,7:11:58",
    status: "Active",
  },
];

export default function (props) {
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleOpenBlockUnblock = () => {
    setOpen(true);
  };
  const [open, setOpen] = React.useState(false);
  const [userData, setUserData] = useState([]);
  const [idd1, setidd1] = React.useState([]);
  const [currentvalue, setCurrentValue] = useState("All Users");

  const [currentvalue1, setCurrentValue1] = useState("Delhi");
  const [states, setStates] = useState("Delhi");
  const [destrict, setDistrict] = useState("Delhi");
  const history = useHistory();
  const [usertype, setusertype] = useState("All Users");
  const [openUserBlockUnblock, setOpenUserBlockUnblick] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const { account, library, chainId } = useWeb3React();

  const [toDate, settoDate] = useState();
  const [fromDate, setfromDate] = useState();
  const [search, setSearch] = useState();
  const classes = useStyles();
  const user = useContext(UserContext);
  const [userlist, setuserlist] = useState();
  const usertoken = user?.userDataStatus?.token;
  const [page, setpages] = useState("");
  const [numpages, setNumpages] = useState("");
  const [loader, setloader] = useState(false);
  const [usereSlect, setUsereSlect] = useState([]);
  console.log("lsjfksdf", usereSlect);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckeded, setisCheckeded] = useState(false);
  const [totalusers, settotaluser] = useState();
  const [userlistofusers, setuserlistofusers] = useState([]);

  console.log("sdjlkjsldfjk", userlist);
  const handleClose = () => {
    setOpen(false);
  };

  const whitelistuser = async () => {
    setloader(true);
    try {
      const url = ApiConfig.userwhitelist;
      const res = await axios.get(url, {
        headers: {
          token: usertoken,
        },
        params: {
          page,
          search: search ? search : "",
        },
      });
      console.log("lsfdkj", res);
      if (res?.data?.statusCode === 200) {
        setuserlist(res?.data?.result.docs);
        setNumpages(res.data.result.pages);
        settotaluser(res.data.result.total);

        setloader(false);
      } else {
        setuserlist([]);
        setloader(false);
      }
    } catch {
      setuserlist([]);
      setloader(false);

      //   alert("error");
    }
  };
  useEffect(() => {
    if (usertoken) {
      whitelistuser();
    }
  }, [usertoken, search, page]);

  useEffect(() => {
    if (totalusers) {
      checkalluserswhitlist();
    }
  }, [totalusers]);
  const checkalluserswhitlist = async () => {
    setloader(true);
    try {
      const url = ApiConfig.userwhitelist;
      const res = await axios.get(url, {
        headers: {
          token: usertoken,
        },
        params: {
          limit: totalusers,
        },
      });
      console.log("lsfdkj", res);
      if (res?.data?.statusCode === 200) {
        setuserlistofusers(res?.data?.result.docs);
        setloader(false);
      } else {
        setuserlist([]);
        setloader(false);
      }
    } catch {
      setuserlist([]);
      setloader(false);

      //   alert("error");
    }
  };
  const handleWithdraw = async (values) => {
    setIsProcessing(true);
    if (account) {
      const web3 = (window.web3 = new Web3(window.ethereum));

      try {
        const contract = getContract(
          contractKovan,
          GenerativeNFTABI,
          library,
          account
        );
        console.log("ksjdhfkjdsf", contract);

        let walletAddressData;
        let arr1Wallet = [];
        for (let i = 0; i < usereSlect.length; i++) {
          walletAddressData = usereSlect[i].walletAddress;
          arr1Wallet.push(walletAddressData);
        }
        console.log("ksjdhfkjdsf", arr1Wallet);

        const whitelistaddress = await contract.addFreeWhiteListers(arr1Wallet);
        // await whitelistaddress.wait();
        console.log("ksjdhfkjdsf", whitelistaddress);

        toast.success("success whitelist");
        // if (trxHash === "SUCCESS") {
        //   try {
        //     const dataToSend = {
        //       txn_hash: txHas,
        //       wallet_address: arr1Wallet,
        //       amount: ammount,
        //     };
        //     const res = await postDataHandler("multiUserWithdraw", dataToSend);
        //     console.log("response=----deposit", res);
        //     if (res.data.statusCode === 200) {
        //       auth.getDataAPIHandler();
        //       toast.success("Your withdraw has been completed successfully.");
        //       withdrawDataHandler();
        //       setIsLogout(false);
        //       setIsProcessing(false);
        //     } else if (res) {
        //       setIsProcessing(false);
        //     }
        //   } catch (error) {
        //     setIsProcessing(false);
        //     console.log(error);
        //   }
        // } else {
        //   toast.error(`Withdraw hash not found ${trxHash}`);
        //   setIsProcessing(false);
        // }
      } catch (error) {
        console.log("error", error);
        setIsProcessing(false);
      }
    } else {
      // swichNetworkHandler();
      setIsProcessing(false);
    }
  };

  //   useEffect(() => {
  //     const filterdata = userlist?.filter((data) => {
  //       console.log("slkdfjksjdf", search === data?.email);

  //       if (search === data?.email) {
  //         setuserlist(data);
  //       }
  //     });
  //   }, [search]);
  const handleopendata = (data) => {
    setIsLogout(true);
  };

  useEffect(() => {
    if (isCheckeded === true) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [isCheckeded]);

  const downloadExcel = () => {
    const workSheet = XLSX.utils.json_to_sheet(userlist);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "User List");
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "Transaction_History.xlsx");
  };
  return (
    <>
      <Container style={{ position: "relative", marginBottom: "50px" }}>
        <Page title="User Management">
          <Box className={classes.mainbox}>
            <Box>
              <Box className={classes.devicelistHeading}>
                <Typography variant="h3">Users List</Typography>
              </Box>
            </Box>
            <Box>
              <Box className={classes.filterBox} mb={5} mt={4}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginBottom: "15px",
                  }}
                >
                  <Box>
                    <TextField
                      id="outlined-basic"
                      type="search"
                      variant="outlined"
                      placeholder="Search Here..."
                      fullWidth
                      className={classes.textFeild}
                      onChange={(e) => setSearch(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              style={{
                                fontSize: "20px",
                                padding: "0px",
                                paddingRight: "9px",
                              }}
                              className="iconbtn"
                            >
                              <BsSearch
                                style={{
                                  color: "#fff",
                                  fontSize: "16px",
                                }}
                              />
                            </IconButton>{" "}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box className={classes.marginsec}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="secondary"
                      onClick={downloadExcel}
                      //   style={{ padding: "8px 0px 8px 0px" }}
                    >
                      Download Xlsx&nbsp;&nbsp;
                      <BsDownload className={classes.download} />
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            {loader ? (
              <ButtonCircularProgress />
            ) : (
              <TableContainer>
                <Table style={{ minWidth: "900px" }}>
                  <TableHead>
                    <TableRow className={`${classes.tablerow1} tableHead`}>
                      <TableCell>S.No.</TableCell>
                      {/* <TableCell>ID</TableCell> */}
                      <TableCell>Email</TableCell>
                      <TableCell>Wallet Address</TableCell>
                      <TableCell>
                        Select All
                        <Checkbox
                          color="primary"
                          className="hoverColor"
                          onClick={(e) => {
                            setisCheckeded(!isCheckeded);
                            if (!isCheckeded) {
                              setUsereSlect(userlistofusers);
                            } else {
                              setUsereSlect();
                            }
                          }}
                          checked={isCheckeded}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userlist?.map((data, i) => (
                      <TableChild
                        i={i}
                        value={data}
                        page={page}
                        setUsereSlect={(data) => setUsereSlect(data)}
                        usereSlect={usereSlect}
                        setIsChecked={setIsChecked}
                        isChecked={isChecked}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {!loader && userlist && userlist?.length == 0 && <NoDataFound />}
          </Box>

          <Dialog
            open={open}
            fullWidth
            maxWidth="sm"
            onClose={() => {
              setOpenUserBlockUnblick(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle align="center" id="alert-dialog-title">
              <Typography variant="h2">Block User</Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" align="center">
                <Typography variant="h5">
                  {" "}
                  Are you sure want to block this user ?
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                variant="contained"
                color="secondary"
              >
                No
              </Button>
              <Button variant="contained" color="primary">
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </Page>
        {userlist?.length == 0 ? (
          ""
        ) : (
          <>
            <Box className={classes.rootss}>
              <Pagination
                onChange={(e, v) => setpages(v)}
                count={parseInt(numpages)}
                color="primary"
              />
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "50px",
              }}
            >
              <Button
                className={classes.profile_btn}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                style={{ height: "48px" }}
                onClick={handleopendata}
                disabled={!isCheckeded}
              >
                Approve
              </Button>
            </Box>
          </>
        )}
        {/* <>
          {usereSlect?.length == 0 ? (
            <>
              {!loader && userlist && userlist?.length === 0 ? (
                ""
              ) : (
                <Box
                  style={
                    loader ? { marginTop: "150px" } : { marginTop: "50px" }
                  }
                  mb={4}
                >
                  <Typography style={{ color: "white" }}>
                    Please select for Whitelist Address
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            <Box style={loader ? { marginTop: "150px" } : {}}>
              <Box className={classes.walletbox}>
                {usereSlect &&
                  usereSlect?.map((data, i) => {
                    return (
                      <>
                        <Typography style={{ color: "white" }}>
                          Wallet Address:-&nbsp;{data?.walletAddress}
                        </Typography>
                      </>
                    );
                  })}
              </Box>
             
            </Box>
          )}
        </> */}

        <Dialog
          className={classes.dailogOpen}
          open={isLogout}
          disabled={isLoading}
          onClose={() => setIsLogout(false)}
          fullWidth
          maxWidth="sm"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle align="center" id="alert-dialog-title">
            <Typography variant="h2">Block User</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" align="center">
              <Typography variant="h5">
                {" "}
                Are you sure want to Whitelist Address
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setIsLogout(false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isProcessing}
              onClick={handleWithdraw}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
