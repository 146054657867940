import { Box } from "@material-ui/core";
import React from "react";

const Logo = (props) => {
  return (
    <Box>
      <img
        src="/images/logo.png"
        alt="Logo"
        {...props}
        width="100%"
        style={{ margin: "0 auto", height: "50px" }}
      />
    </Box>
  );
};

export default Logo;
