import React from "react";
import {
  makeStyles,
  Box,
  Container,
  Typography,
  Button,
  Grid, withStyles
} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import FaqData from "./FaqData";
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root1: {
    padding: "80px 0",
    [theme.breakpoints.down("xs")]: {
      padding: "50px 0",
    },
    "& h3": {
      fontSize: "50px",
      fontWeight: "700",
      fontFamily: "Impact",
      background:
        "linear-gradient(180deg, #68E8F5 0%, rgba(29, 132, 199, 0) 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
      paddingBottom: "30px",
      "& img": {
        marginRight: "20px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
    },
    "& h6": {
      fontSize: "17px",
      // fontWeight: "700",
      fontFamily: "Impact",
      background:
        "linear-gradient(180deg, #68E8F5 0%, rgba(29, 132, 199, 0) 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
      textFillColor: "transparent",
      paddingBottom: "0",
      margin: "0",
      "& img": {
        marginRight: "20px",
      },
      // [theme.breakpoints.down("xs")]: {
      //   fontSize: "20px",
      // },
    },
  },
  marginRemove: {

    "& .MuiAccordion-root.Mui-expanded": {
      marginTop: "0!important",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0px",
    },
    "& .MuiAccordionDetails-root": {
      display: "block",
      padding: "8px 16px",
    },
  },
}));

const Accordion = withStyles({
  root: {
    "&:not(:last-child)": {
      background: "#242121",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.12)",
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      border: " 1px solid #3d3d3d",
      color: "#64DBE7",
      // backdropFilter: "blur(42px)",
    },
    "& .AccordianDetails1": {
      // backdropFilter: "0 !important",
      display: "flex",
      flexDirection: "column",
      borderBottom: "1px solid #64DBE7",
      borderLeft: "1px solid #64DBE7",
      // borderRight: "1px solid #64DBE7",
      borderRight: "1px solid #64DBE7",
      borderTop: "0px",
    },
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    background: "#242121",
    border: "1px solid #68E8F5",
    boxSizing: "border-box",
    // backdropFilter: "blur(4px)",
    color: "#909090",
    "&$expanded": {
      minHeight: 50,
      borderBottom: "0",
      color: "#64DBE7 !important",
    },
    "@media(max-width:605px)": {
      fontSize: "10px",
      minHeight: 50,
      "&$expanded": {
        minHeight: 40,
        borderBottom: "0",
        color: "#64DBE7 !important",
      },
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {
    margin: "0",
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    background: "#242121",
    boxSizing: "border-box",
    // backdropFilter: "blur(4px)",
    // marginTop: "10px",
    "& h6": {
      color: "#000",
      // paddingBottom: "15px",
    },
    "& p": {
      color: "#fff",
      textAlign: "left",
      paddingBottom: "15px"
    },
  },
}))(MuiAccordionDetails);
const FaqDataList = [
  {
    head: "How do I stake tokens on Tarality Metaverse?",
    // heading: "Why do I need a will or letter of wishes?",
    summary: [
      <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
        <h6 >Step 1 - </h6> &nbsp;
        <p style={{ margin: "0" }}>
          Go to Tarality Metaverse Platform {">"} Click on 'Stake' option in the menu bar.
        </p>
      </div>,
      <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
        <h6 >Step 2 - </h6> &nbsp;
        <p style={{ margin: "0" }}>
          Enter the amount you want to stake
        </p>
      </div>,
      <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
        <h6 >Step 3 - </h6> &nbsp;
        <p style={{ margin: "0" }}>
          Connect your Wallet (Metamask)
        </p>
      </div>,
      <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
        <h6 >Step 4 - </h6> &nbsp;
        <p style={{ margin: "0" }}>
          Calculate your staking value in the 'Staking Calculator' {"&"} <b>Stake</b>
        </p>
      </div>,
    ],
  },
  {
    head: "How do I unstake tokens on Tarality Metaverse?",
    // heading: "Why do I need a will or letter of wishes?",
    summary: [
      <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
        <h6 >Step 1 - </h6> &nbsp;
        <p style={{ margin: "0" }}>
          Go to Tarality Metaverse Platform {">"}  Click on 'Stake' option in the menu bar.
        </p>
      </div>,
      <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
        <h6 >Step 2 - </h6> &nbsp;
        <p style={{ margin: "0" }}>
          Enter the amount you want to unstake
        </p>
      </div>,
      <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
        <h6 >Step 3 - </h6> &nbsp;
        <p style={{ margin: "0" }}>
          Connect your Wallet (Metamask)
        </p>
      </div>,
    ],
  },
  {
    head: "How do I launch a satellite?",
    // heading: "Why do I need a will or letter of wishes?",
    summary: [
      <div>
        <p style={{ margin: "0" }}>
          Stake your Tarality tokens and mint Satellites, and launch them into space. If you stake enough, your Satellite can become an Orbiter.

        </p>
      </div>,
    ],
  },
  {
    head: "How do I mint an NFT?",
    // heading: "Why do I need a will or letter of wishes?",
    summary: [
      <div>
        <p style={{ margin: "0" }}>
          Stake Revolution tokens to mint rocket NFT. Staking generates Revolution tokens, which are used to mint NFTs on the BEP- 20 network.
        </p>
      </div>,
    ],
  },
  {
    head: "How do I claim my staking rewards?",
    // heading: "Why do I need a will or letter of wishes?",
    summary: [
      <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
        <h6 >Step 1 - </h6> &nbsp;
        <p style={{ margin: "0" }}>
          Go to Tarality Metaverse Platform {">"} Click on 'Stake' option in the menu bar.
        </p>
      </div>,
      <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
        <h6 >Step 2 - </h6> &nbsp;
        <p style={{ margin: "0" }}>
          Scroll down to the claim reward box
        </p>
      </div>,
      <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
        <h6 >Step 3 - </h6> &nbsp;
        <p style={{ margin: "0" }}>
          Connect your wallet {"&"} Claim your reward
        </p>
      </div>,
    ],
  },
];

function Faq() {
  const classes = useStyles();
  const history = useHistory();
  const [expanded, setExpanded] = React.useState(0);

  const handleChange = (panel) => (event, newExpanded) => {
    console.log("panel-----panel", panel);
    setExpanded(panel);
  };
  return (
    <>
      <Box className={classes.root1}>
        <Container maxWidth="lg">
          <Box align="center" mt={2}>
            <Typography variant="h3" style={{ color: "#383838" }}>
              FAQ’s
            </Typography>
          </Box>

          <Box mt={5} mb={3} className={classes.marginRemove}>
            <Grid container spacing={1}>
              {FaqDataList.map((data, index) => {
                return (
                  <Grid item xs={12} sm={12} md={12} key={index}>
                    {/* <FaqData data={data} index={i} /> */}
                    <Accordion
                      square
                      expanded={expanded === index}
                      onChange={handleChange(index)}
                      style={{ background: "red !important" }}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >

                      <AccordionSummary
                        aria-controls="panel1d-content"
                        expandIcon={
                          <ExpandMoreIcon style={{ fontSize: "23px", fontWeight: "400" }} />
                        }
                      >
                        <Typography variant="h6" style={{ fontSize: "18px" }}>
                          {data.head}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className="AccordianDetails1"

                      >
                        {data.summary}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}
export default Faq;
