import React from "react";
import { withStyles, Box } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  marginRemove: {

    "& .MuiAccordion-root.Mui-expanded": {
      marginTop: "0!important",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0px",
    },
    "& .MuiAccordionDetails-root": {
      display: "block",
      padding: "8px 16px",
    },
  },
}))
const Accordion = withStyles({
  root: {
    "&:not(:last-child)": {
      background: "#242121",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.12)",
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      border: " 1px solid #3d3d3d",
      color: "#64DBE7",
      // backdropFilter: "blur(42px)",
    },
    "& .AccordianDetails1": {
      // backdropFilter: "0 !important",
      display: "flex",
      flexDirection: "column",
      borderBottom: "1px solid #64DBE7",
      borderLeft: "1px solid #64DBE7",
      // borderRight: "1px solid #64DBE7",
      borderRight: "1px solid #64DBE7",
      borderTop: "0px",
    },
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    background: "#242121",
    border: "1px solid #68E8F5",
    boxSizing: "border-box",
    // backdropFilter: "blur(4px)",
    color: "#909090",
    "&$expanded": {
      minHeight: 50,
      borderBottom: "0",
      color: "#64DBE7 !important",
    },
    "@media(max-width:605px)": {
      fontSize: "10px",
      minHeight: 50,
      "&$expanded": {
        minHeight: 40,
        borderBottom: "0",
        color: "#64DBE7 !important",
      },
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {
    margin: "0",
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    background: "#242121",
    boxSizing: "border-box",
    // backdropFilter: "blur(4px)",
    // marginTop: "10px",
    "& h6": {
      color: "#000",
      paddingBottom: "15px",
    },
    "& p": {
      color: "#fff",
      textAlign: "left",
    },
  },
}))(MuiAccordionDetails);
export default function FaqData({ data, index }) {
  const [expanded, setExpanded] = React.useState(0);
  const classes = useStyles()

  const handleChange = (panel) => (event, newExpanded) => {
    console.log("panel-----panel", panel);
    setExpanded(panel);
  };
  console.log(index, expanded, "panel-----expanded", expanded === index);

  return (
    <>
      <Box className={classes.marginRemove}>
        <Accordion
          square
          expanded={expanded === index}
          onChange={handleChange(index)}
          style={{ background: "red !important" }}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >

          <AccordionSummary
            aria-controls="panel1d-content"
            expandIcon={
              <ExpandMoreIcon style={{ fontSize: "23px", fontWeight: "400" }} />
            }
          >
            <Typography variant="h6" style={{ fontSize: "18px" }}>
              {data.head}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="AccordianDetails1"

          >
            <Typography variant="body2">{data.summary}</Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}
