import { Box, makeStyles, Grid, Typography, Button } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "linear-gradient(180deg, #1D1C1C 0%, rgba(29, 28, 28, 0) 100%)",
    backdropFilter: "blur(42px)",
    borderRadius: "10px",

    "& h3": {
      fontFamily: "'Impact'",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "32px",
      lineHeight: "39px",
      textAlign: "center",
      color: "#FFFFFF",
    },
    "& h6": {
      fontFamily: "'Roboto'",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
      color: "#FFFFFF",
      padding: "10px 25px 44px",
      textAlign: "left",
    },
  },
  createbutton: {
    fontSize: "14px ",
    fontWeight: 700,
    height: "44px ",
    background: "linear-gradient(280deg,#78FDFF 0%, #1D84C7 100%)",
    color: "#fff",
    borderRadius: "10px",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: "45px ",
      minWidth: "120px ",
    },
    "&:hover": {
      color: "#fff",
      background: "#1D84C7",
      transition: "none",
    },
  },
}));

const BuyStaking = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Box>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box>
              <Box className={classes.root}>
                <Box style={{ paddingTop: "20px" }}>
                  <Typography variant="h3">Buy</Typography>

                  <Typography variant="h6">
                    Don't miss your chance to get in on the action! You can now buy the Revolution token on Tarality Metaverse. This is your chance to be a part of the virtual world's first cryptocurrency. With the Revolution token, you can trade, buy, and sell in-game items and services. So what are you waiting for? Get your Revolution token today!
                  </Typography>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    size="large"
                    className={classes.createbutton}
                    onClick={() =>
                      history.push({
                        pathname: "/app/Buycoin",
                      })
                    }
                  >
                    Buy
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box>
              <Box className={classes.root}>
                <Box style={{ paddingTop: "20px" }}>
                  <Typography variant="h3">Stake</Typography>

                  <Typography variant="h6">
                    Revolution tokens are the lifeblood of the Tarality Metaverse. Through staking, users will be incentivized to participate in this next-generation social virtual reality platform and earn high-interest rates for their holdings. If you have been waiting for a chance to get involved in new technology as innovative and revolutionary as blockchain, then now is your chance.
                    <Typography variant="h6" style={{ padding: "5px 0" }}>
                      So what are you waiting for? Stake your tokens today!
                    </Typography>
                  </Typography>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    size="large"
                    className={classes.createbutton}
                    onClick={() =>
                      history.push({
                        pathname: "/app/Network",
                      })
                    }
                  >
                    Staking
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default BuyStaking;
