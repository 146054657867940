import { Interface } from "@ethersproject/abi";
import Web3 from "web3";
import MultiCallAbi from "../abis/Multicall.json";
import {
  mulltiCallAddressEth,
  mulltiCallAddressMatic,
  mulltiCallAddressBnb,
} from "./index";
import { default_RPC_URL } from "../constants/";
// import { getWeb3Obj } from "src/utils";
import { getWeb3ContractObject, getWeb3Obj } from "./index";

const multicall = async (abi, calls, chainId, rpc) => {
  console.log("chainId", chainId);
  console.log("rpc", rpc);
  // console.log("calls", calls);
  // const multicallAdd =
  //   chainId == 97
  //     ? mulltiCallAddressBnb
  //     : chainId == 80001
  //     ? mulltiCallAddressMatic
  //     : mulltiCallAddressEth;

  const multi = await getWeb3ContractObject(
    MultiCallAbi,
    mulltiCallAddressBnb,
    rpc //default_RPC_URL
  );
  const itf = new Interface(abi);
  const calldata = calls.map((call) => [
    call.address.toLowerCase(),
    itf.encodeFunctionData(call.name, call.params),
  ]);
  const { returnData } = await multi.methods.aggregate(calldata).call();
  const res = returnData.map((call, i) =>
    itf.decodeFunctionResult(calls[i].name, call)
  );
  return res;
};

export default multicall;
