import React, { useState, useEffect } from "react";
import {
  Grid,
  Container,
  Box,
  Typography,
  Button,
  makeStyles,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { toast } from "react-toastify";
import ConnectWallet from "../././../../src/components/ConnectWalletPopUp/index";

import {
  BuyTokenContractaddress,
  BUSD,
  BNB,
  USDT,
  DAI,
  getBuyContractAddress,
} from "../../constants/index";
import BuyContractABI from "../../abis/BuyContractABI.json";
import TRCtokenABI from "../../abis/TRCtokenABI.json";
import { getContract } from "../../utils";
import ButtonCircularProgress from "../../components/Loaders/ButtonCircularProgress";
const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "158px 0px",
    [theme.breakpoints.down("xs")]: {
      padding: "105px 0px",
    },
    "& h6": {
      color: "white",
    },
  },
  mainbox: {
    // backgroundColor: "#1D1C1C",
    // borderRadius: "8px",
    boxShadow: "0px 0px 32px rgb(64 64 64)",
    padding: "20px 20px",
    background: "linear-gradient(180deg, #1D1C1C 0%, rgba(29, 28, 28, 0) 129%)",
    borderRadius: "10px",
    backdropFilter: "blur(42px)",
    maxWidth: "600px",
  },
  selectbox: {
    display: "flex",
    alignItems: "center",
    // paddingTop: "10px",
  },
  ethbox: {
    backgroundColor: "#404040",
    borderRadius: "19px",
    padding: "20px 20px",
    "& p": {
      color: "white",
    },
    "& input": {
      // width: "30px",
    },
  },
  ethereum: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "5px",
    "& p": {
      color: "white",
    },
  },
  childgrid: {
    marginTop: "3rem",
    "& p": {
      color: "white",
    },
  },
  head1: {
    margin: "10px 0",
    padding: "5px",
    borderTop: "1px solid #cacaca36",
    borderBottom: "1px solid #cacaca4a",
  },
  cls: {
    // color: "#FF5678 !important",
  },
  createbutton: {
    fontSize: "14px ",
    marginTop: "15px",
    fontWeight: 700,
    height: "44px ",
    background: "linear-gradient(280deg,#78FDFF 0%, #1D84C7 100%)",
    color: "#fff",

    borderRadius: "10px",

    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: "45px ",
      minWidth: "120px ",
    },
    "&:hover": {
      color: "#fff",
      background: "#1D84C7",
      transition: "none",
    },
  },
  selectboxsec: {
    background: "#111214",
    width: "160px",
    borderRadius: "10px",
  },
  flexbox: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

// 0xe0432C1968cFD5B79495ada0E7Fcec0D2FE87B56

function Exchange() {
  const classes = useStyles();
  const history = useHistory();
  const [currentvalue, setCurrentValue] = useState(BNB);
  const [popupopen, setpopupopen] = useState(false);
  const [amountValue, setAmountValue] = useState("");
  const { account, library, chainId } = useWeb3React();
  const [stakeloader, setstakeloader] = useState();
  const [yourWalletBalance, setYourWalletBalance] = useState();
  const [balancevalue, setamountvalue] = useState();
  const [isSubmits, setIsSubmits] = useState(false);
  const [openWalletConnect, setOpenWalletConnect] = React.useState(false);
  const [trcvalue, setTrcvalue] = useState("");
  console.log("asfasdfasf", balancevalue);
  const selectedCurrency =
    currentvalue === BUSD
      ? "BUSD"
      : currentvalue === USDT
      ? "USDT"
      : currentvalue === DAI
      ? "DAI"
      : "BNB";

  const handleValidationCheck = (values) => {
    setIsSubmits(true);
    if (amountValue !== "") {
      if (Number(balancevalue) >= Number(amountValue)) {
        getBuyTokenHandler();
        setIsSubmits(false);
      }
    }
  };
  const getBuyTokenHandler = async () => {
    try {
      setstakeloader(true);
      if (account) {
        const web3 = (window.web3 = new Web3(window.ethereum));
        const contract = getContract(
          BuyTokenContractaddress,
          BuyContractABI,
          library,
          account
        );
        console.log("askdfhkahsdfk", contract);
        if (selectedCurrency === "BNB") {
          const tranferFunBNB = await contract.buyToken(0, 0, {
            value: web3.utils.toWei(amountValue).toString(),
          });
          await tranferFunBNB.wait();
          setstakeloader(false);
          setpopupopen(true);

          toast.success("Your transcation has been completed successfully");
          setAmountValue("");
          setTimeout();
        } else {
          const currencyToSend =
            selectedCurrency === "DAI"
              ? 1
              : selectedCurrency === "BUSD"
              ? 2
              : 3;
          const addressCheck = getBuyContractAddress(selectedCurrency);
          console.log("addressCheck---", addressCheck);
          const contractapprove = getContract(
            addressCheck,
            TRCtokenABI,
            library,
            account
          );
          const approveFun = await contractapprove.approve(
            BuyTokenContractaddress,
            web3.utils.toWei(amountValue).toString()
          );
          await approveFun.wait();
          const tranferFun = await contract.buyToken(
            currencyToSend,
            web3.utils.toWei(amountValue).toString()
          );
          await tranferFun.wait();
          setstakeloader(false);
          setpopupopen(true);
          toast.success("Your transcation has been completed successfully");
          setAmountValue("");
        }
      } else {
        toast.error("Please connect your wallet first!");
        setstakeloader(false);
      }
    } catch (error) {
      setstakeloader(false);
      toast.error(error);
    }
  };

  const gerbalance = async () => {
    var web3 = new Web3(library.provider);
    const addressCheck = getBuyContractAddress(selectedCurrency);
    const contractapprove = getContract(
      addressCheck,
      TRCtokenABI,
      library,
      account
    );
    const getbalace = await contractapprove.balanceOf(account);
    setamountvalue(web3.utils.fromWei(getbalace.toString()));
  };
  useEffect(() => {
    gerTrcbalance();
  }, [amountValue]);

  const gerTrcbalance = async () => {
    try {
      const web3 = (window.web3 = new Web3(window.ethereum));
      const contract = getContract(
        BuyTokenContractaddress,
        BuyContractABI,
        library,
        account
      );

      console.log("sdfksdhfkjsdfh", contract);
      if (selectedCurrency === "BNB") {
        const getbalace = await contract.getPrice(
          0,
          web3.utils.toWei(amountValue).toString()
        );

        setTrcvalue(web3.utils.fromWei(getbalace.toString()));
      } else {
        const currencyToSend =
          selectedCurrency === "DAI" ? 1 : selectedCurrency === "BUSD" ? 2 : 3;
        const getbalace = await contract.getPrice(
          currencyToSend,
          web3.utils.toWei(amountValue).toString()
        );

        setTrcvalue(web3.utils.fromWei(getbalace.toString()));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (account) {
      getUserbalce();
      gerbalance();
    }
  }, [account, selectedCurrency]);

  const getUserbalce = async () => {
    var web3 = new Web3(library.provider);
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = await web3.utils.fromWei(balance);
    setamountvalue(parseFloat(balanceImETH).toFixed(10));
  };
  useEffect(() => {
    if (selectedCurrency) {
      setAmountValue("");
    }
  }, [selectedCurrency]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box className={classes.wrapper}>
      <Container maxWidth="sm">
        <Box className={classes.mainbox}>
          <Box className={classes.ethbox}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography variant="body1">Choose Currency</Typography>
              </Box>
              <Box className={classes.selectbox}>
                <Box ml={2} className={classes.selectboxsec}>
                  <Box
                    className="stakInput numberTextField"
                    style={{ width: "100%" }}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.forminput}
                      style={{ display: "flex" }}
                    >
                      <Select
                        margin="dense"
                        className="input1"
                        style={{ display: "flex" }}
                        name="token"
                        onChange={(e) => setCurrentValue(e.target.value)}
                        value={currentvalue}
                        disabled={!account || stakeloader}
                      >
                        <MenuItem value={BNB} style={{ fontSize: "12px" }}>
                          BNB
                        </MenuItem>
                        <MenuItem value={BUSD} style={{ fontSize: "12px" }}>
                          BUSD
                        </MenuItem>
                        <MenuItem value={USDT} style={{ fontSize: "12px" }}>
                          USDT
                        </MenuItem>
                        <MenuItem value={DAI} style={{ fontSize: "12px" }}>
                          DAI
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box mt={2} className={classes.ethbox} style={{ marginTop: "14px" }}>
            <Box
              className="stakInput numberTextField"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1">Amount to be used</Typography>
              </Box>
              <Box>
                <Box
                  style={{ display: "flex" }}
                  className={classes.selectboxsec}
                >
                  <TextField
                    className="input1"
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="0.0"
                    fullWidth
                    autoComplete="nope"
                    type="number"
                    value={amountValue}
                    disabled={!account || stakeloader}
                    onChange={(e) => setAmountValue(e.target.value)}
                  />

                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingRight: "10px",
                    }}
                  >
                    <Typography variant="body1">{selectedCurrency}</Typography>
                  </Box>
                </Box>

                <FormHelperText
                  style={{
                    fontSize: "12px",
                    color: "#f44336",
                    position: "absolute",
                    marginTop: "0px",
                  }}
                >
                  {isSubmits && amountValue === "" ? (
                    "Please enter amount"
                  ) : (
                    <>
                      {isSubmits && Number(balancevalue) <= Number(amountValue)
                        ? "Enter valid amount"
                        : ""}
                    </>
                  )}
                </FormHelperText>
              </Box>
            </Box>
          </Box>

          <Box className={classes.childgrid}>
            {account ? (
              <>
                <Box className={classes.flexbox}>
                  <Typography variant="body1">Available Balance</Typography>
                  <Typography variant="body1">
                    {balancevalue} {selectedCurrency}
                  </Typography>
                </Box>

                {/* <Box className={classes.flexbox} style={{ marginTop: "11px" }}>
                  <Typography variant="body1">Amount to be paid</Typography>
                  <Typography variant="body1" className={classes.cls}>
                    {amountValue} {selectedCurrency}
                  </Typography>
                </Box> */}

                <Box className={classes.head1}>
                  <Box className={classes.flexbox}>
                    <Typography variant="body2">RVLNG you receive</Typography>
                    <Typography variant="body1">{trcvalue} RVLNG</Typography>
                  </Box>
                </Box>
              </>
            ) : (
              ""
            )}
            <Box style={{ display: "flex", justifyContent: "center" }}>
              {account ? (
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                  className={classes.createbutton}
                  onClick={handleValidationCheck}
                  disabled={amountValue == 0 || stakeloader}
                >
                  Submit{" "}
                  {stakeloader && (
                    <Box style={{ marginLeft: "5px", marginTop: "5px" }}>
                      <ButtonCircularProgress />
                    </Box>
                  )}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="large"
                  style={
                    !account
                      ? { marginTop: "-27px" }
                      : {
                          whiteSpace: "nowrap",
                        }
                  }
                  className={classes.createbutton}
                  onClick={() => setOpenWalletConnect(true)}
                >
                  Connect Wallet
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
      <Dialog
        open={popupopen}
        onClose={() => setpopupopen(false)}
        disableScrollLock={true}
        className={classes.connectWalletModal}
      >
        <Box textAlign="center" style={{ margin: "16px" }}></Box>
        <DialogContent>
          <Box align="center">
            <Typography
              variant="h5"
              style={{ color: "#fff", marginTop: "-10px" }}
            >
              Successfully you receive {trcvalue} RVLNG
            </Typography>
          </Box>
        </DialogContent>
        <Box style={{ margin: "16px", textAlign: "center" }}>
          <Button
            onClick={() => setpopupopen(false)}
            variant="contained"
            color="secondary"
            style={{ cursor: "pointer" }}
          >
            Close
          </Button>
        </Box>
      </Dialog>
      {openWalletConnect && (
        <ConnectWallet
          open={openWalletConnect}
          handleClose={() => setOpenWalletConnect(false)}
        />
      )}
    </Box>
  );
}
export default Exchange;
